import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useLocation, Location } from "react-router-dom";
import { RouteNames } from "../RouteNames";

const getRedirectLocation = (location: Location) =>
  location.state && location.state.from
    ? `${location.state.from.pathname}${location.state.from.search}`
    : RouteNames.ROOT;

export function LoginPage() {
  const location = useLocation();
  useEffect(() => {
    document.location.href = `/login?redirect_uri=${encodeURIComponent(
      getRedirectLocation(location)
    )}`;
  }, [location]);
  return <LinearProgress />;
}
