import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQuery, useQueryClient } from "react-query";

export function useModeShareHash({
  modeId,
  readonly: readOnly = false,
}: {
  modeId?: string;
  readonly?: boolean;
}) {
  const client = useApiClient();
  return useQuery<{ share_hash: string }>(
    ["share", modeId, readOnly],
    () =>
      client.fetch<{ share_hash: string }>(
        `/api/modes/${modeId}/share?read_only=${readOnly}`,
        {
          method: "POST",
        }
      ),
    {
      enabled: !!modeId,
    }
  );
}

export function useModeCreateAccess() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      mode_id,
      shared_with_user_id,
      read_only,
    }: {
      mode_id: string;
      shared_with_user_id: string;
      read_only?: boolean;
    }) => {
      return client.fetch<{ share_hash: string }>(
        `/api/modes/${mode_id}/access/${shared_with_user_id}?read_only=${read_only}`,
        {
          method: "POST",
        }
      );
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["mode-access"],
        });
      },
    }
  );
}

export function useModeDeleteAccess() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      mode_id,
      shared_with_user_id,
    }: {
      mode_id: string;
      shared_with_user_id: string;
    }) => {
      return client.fetch<{ share_hash: string }>(
        `/api/modes/${mode_id}/access/${shared_with_user_id}`,
        {
          method: "DELETE",
        }
      );
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["mode-access"],
        });
      },
    }
  );
}
