import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useConversationDelete() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) =>
      client.fetch(`/api/history/conversation/${id}`, { method: "DELETE" }),
    {
      async onSettled(_, __, id) {
        queryClient.removeQueries(["conversation", id]);
        await queryClient.invalidateQueries({
          queryKey: ["conversation"],
        });
      },
    }
  );
}
