import { useApiClient } from "@myplant-io/utils/api";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { commonMessages } from "../commonMessages";
import { Mode } from "../types";

export const defaultMode = "__default__";
export const noInnioMode = "__no_innio__";

export function useModes() {
  const client = useApiClient();
  return useQuery<Mode[]>("modes", () => client.fetch("/api/modes"));
}

export function useModeName() {
  const { formatMessage: t } = useIntl();
  const { data: modes } = useModes();
  return (id?: string) => {
    const m = modes?.find((m) => m._id === id)?.name || "";
    if (m === defaultMode) {
      return t(commonMessages.defaultMode);
    }
    if (m === noInnioMode) {
      return t(commonMessages.noInnioMode);
    }
    return m;
  };
}
