import { useLayoutEffect, useRef } from "react";

type Callback = (now: number, delta: number) => void;
export function useRequestAnimationFrame(cb: Callback, time: number) {
  const cbRef = useRef<Callback>();
  const frame = useRef<number>();
  const last = useRef<number>();
  const prev = useRef<number>();

  cbRef.current = cb;

  useLayoutEffect(() => {
    const animate = (now: number) => {
      const delta = prev.current ? now - prev.current : 0;
      prev.current = now;
      if (now - (last.current ?? 0) >= time) {
        last.current = now;
        cbRef.current?.(now, delta);
      }
      frame.current = requestAnimationFrame(animate);
    };
    frame.current = requestAnimationFrame(animate);
    return () => {
      frame.current && cancelAnimationFrame(frame.current);
    };
  }, [time]);
}
