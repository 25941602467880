import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useConversationsDelete() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    () => client.fetch(`/api/history/conversation`, { method: "DELETE" }),
    {
      async onSettled() {
        queryClient.removeQueries(["conversation"]);
        await queryClient.invalidateQueries({
          queryKey: ["conversation"],
        });
      },
    }
  );
}
