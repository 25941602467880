import { useTheme } from "@mui/material";
import Color from "color";
import classes from "./LoadingAnimation.module.css";

export function LoadingAnimation() {
  const theme = useTheme();
  const bgColor = Color(theme.palette.secondary.main).array().join(",");
  return (
    <>
      <style>
        {`
        :root {
          --background-color: rgb(${bgColor});
          --background-color-light: rgba(${bgColor}, 0.2);
        }
      `}
      </style>
      <div className="p-2">
        <div className={classes["dot-flashing"]}></div>
      </div>
    </>
  );
}
