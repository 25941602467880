import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { FormattedMessage } from "react-intl";

type Props = IconButtonProps & {
  text: string;
};

export function CopyToClipboardButton({ text, ...rest }: Props) {
  const copy = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="copyToClipboardButton.tooltip"
          defaultMessage="Copy to clipboard"
        />
      }
    >
      <span>
        <IconButton onClick={copy} {...rest}>
          <ContentCopy fontSize={rest.size} />
        </IconButton>
      </span>
    </Tooltip>
  );
}
