import {
  Autocomplete,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  styled,
  autocompleteClasses,
  Popper,
} from "@mui/material";
import { useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useModeAvailableUsers } from "../../hooks/useModeAvailableUsers";
import { Mode, ModeUser } from "../../types";
import PersonAdd from "@mui/icons-material/PersonAdd";
import { useModeCreateAccess } from "../../hooks/useModeShare";
import { useModeAccess } from "../../hooks/useModeAccess";
import { commonMessages } from "../../commonMessages";
import { VirtualizedListboxComponent } from "./VirtualizedListboxComponent";

const messages = defineMessages({
  readOnly: {
    id: "modeUserPicke.readOnly",
    defaultMessage: "Readonly",
  },
  editable: {
    id: "modeUserPicke.editable",
    defaultMessage: "Editable",
  },
});

type Props = {
  mode?: Mode;
};

export function ModeUserPicker({ mode }: Props) {
  const { formatMessage: t } = useIntl();
  const { data: _options, isLoading } = useModeAvailableUsers();
  const [readOnly, setReadOnly] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<ModeUser[]>([]);
  const { data: modeAccess } = useModeAccess(mode?._id);
  const { mutateAsync: _createAccess } = useModeCreateAccess();

  // filter out users that the mode is already shared with
  const options = useMemo(() => {
    const users = new Set(
      modeAccess?.shared_with.map((u) => u.user.username) || []
    );
    return _options?.filter((o) => !users.has(o.username));
  }, [_options, modeAccess?.shared_with]);

  const shareMode = async (users: ModeUser[], readOnly: boolean = true) => {
    await Promise.all(
      users.map((user) =>
        _createAccess({
          mode_id: mode?._id || "",
          shared_with_user_id: user.username,
          read_only: readOnly,
        })
      )
    );
    setSelectedUsers([]);
  };

  return (
    <div className="flex items-center">
      <div className="mr-4 flex-1 overflow-hidden">
        <Autocomplete
          fullWidth
          id="user-permission-picker"
          disableCloseOnSelect
          loading={isLoading}
          options={options || []}
          isOptionEqualToValue={(option, value) =>
            option.username === value.username
          }
          getOptionLabel={(option) => `${option.full_name} (${option.email})`}
          getOptionKey={(option) => option.username}
          multiple
          limitTags={2}
          value={selectedUsers}
          onChange={(_, value: ModeUser[]) => {
            setSelectedUsers(value);
          }}
          sx={{
            "&.MuiAutocomplete-root": {
              paddingTop: 0.5,
            },
            "& .MuiAutocomplete-inputRoot": {
              paddingRight: "1rem !important",
            },
            "& .MuiAutocomplete-input": {
              minWidth: "50% !important",
            },
          }}
          renderInput={(params) => (
            <div className="w-full">
              <TextField
                {...params}
                label="Users"
                placeholder="Users"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <FormControl sx={{ mr: 0, minWidth: 120 }} size="small">
                      <Select
                        label="permission"
                        variant="standard"
                        value={readOnly.toString()}
                        onChange={(e) => setReadOnly(e.target.value === "true")}
                      >
                        <MenuItem value={"true"}>
                          {t(messages.readOnly)}
                        </MenuItem>
                        {!mode?.read_only && (
                          <MenuItem value={"false"}>
                            {t(messages.editable)}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  ),
                }}
              />
            </div>
          )}
          PopperComponent={StyledPopper}
          ListboxComponent={VirtualizedListboxComponent}
        />
      </div>

      <Tooltip title={t(commonMessages.addUser)}>
        <span>
          <IconButton
            size="small"
            onClick={() => shareMode(selectedUsers, readOnly)}
          >
            <PersonAdd fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});
