import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useConversationUpdate() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, title }: { id: string; title: string }) =>
      client.fetch(`/api/history/conversation/${id}`, {
        method: "PUT",
        body: JSON.stringify({ title }),
      }),
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["conversation"],
        });
      },
    }
  );
}
