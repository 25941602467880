import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

type ModeWithFile = {
  _id: string;
  name: string;
  temperature: number;
  no_innio_context: boolean;
  document_types: string[] | null;
  enabled_functions: string[] | null;
  system_prompt_addition: string;
  disable_animation: boolean;
};

export function useModeUpdate(
  options?: UseMutationOptions<unknown, ApiError, ModeWithFile>
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      _id,
      name,
      temperature,
      no_innio_context,
      document_types,
      enabled_functions,
      system_prompt_addition,
      disable_animation,
    }: ModeWithFile) => {
      return client.fetch(`/api/modes/${_id}`, {
        method: "PUT",
        body: JSON.stringify({
          name,
          temperature,
          no_innio_context,
          system_prompt_addition,
          document_types,
          enabled_functions,
          disable_animation,
        }),
      });
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
