import { PropsWithChildren, useEffect, useRef } from "react";
import { useAppState } from "./App";

export function TextToSpeechProvider({ children }: PropsWithChildren<unknown>) {
  const {
    state: { isPlaying, currentTTSUrl: textToSpeechUrl },
    actions: { setIsPlaying, setAutoPlayNotAllowed },
  } = useAppState();
  const ref = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const play = async () => {
      try {
        await ref.current?.play();
      } catch (error) {
        setIsPlaying(false);
        setAutoPlayNotAllowed(true);
      }
    };
    if (isPlaying) {
      play();
      return;
    }
    ref.current?.pause();
  }, [isPlaying, setAutoPlayNotAllowed, setIsPlaying]);

  return (
    <>
      {textToSpeechUrl && (
        <audio
          ref={ref}
          src={textToSpeechUrl}
          loop={false}
          onAbort={() => setIsPlaying(false)}
          onEnded={() => setIsPlaying(false)}
        />
      )}
      {children}
    </>
  );
}
