import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

export function useModeAttachmentsAdd(
  options?: UseMutationOptions<
    unknown,
    ApiError,
    { modeId: string; attachments: File[] }
  >
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    ApiError,
    { modeId: string; attachments: File[] }
  >(
    ({ modeId, attachments }) => {
      const formData = new FormData();
      attachments.forEach((file) => {
        formData.append("attachments", file);
      });
      return client.fetch(`/api/modes/${modeId}/attachments`, {
        method: "POST",
        body: formData,
      });
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
