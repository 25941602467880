import { useAppState } from "../../state/App";
import React, { useState } from "react";
import ChatHistoryList from "./ChatHistoryList";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConversationHistory } from "../../hooks/useConversationHistory";
import { commonMessages } from "../../commonMessages";
import { useIntl } from "react-intl";
import { useConversationsDelete } from "../../hooks/useConversationsDelete";

interface ChatHistoryPanelProps {}

export enum ChatHistoryPanelTabs {
  History = "History",
}

export function ChatHistoryPanel(_: ChatHistoryPanelProps) {
  const { formatMessage: t } = useIntl();
  const conversationHistory = useConversationHistory();
  const {
    actions: { setCurrentConversationId, setMessages },
  } = useAppState();
  const [showClearAllDialog, setShowClearAllDialog] = useState(false);
  const [clearingError, setClearingError] = useState(false);
  const { mutateAsync: deleteConversations } = useConversationsDelete();

  const [ctxMenuAnchorEl, setCtxMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const ctxMenuOpen = Boolean(ctxMenuAnchorEl);
  const onShowContextualMenu = (event: React.MouseEvent<HTMLElement>) => {
    setCtxMenuAnchorEl(event.currentTarget);
  };
  const onHideContextualMenu = () => {
    setCtxMenuAnchorEl(null);
  };

  const onClearAllChatHistory = async () => {
    try {
      await deleteConversations();
      setCurrentConversationId(undefined);
      setMessages([]);
      setShowClearAllDialog(false);
    } catch (error) {
      setClearingError(true);
    }
  };

  const onHideClearAllDialog = () => {
    setClearingError(false);
    setShowClearAllDialog(false);
  };

  return (
    <section
      className="mt-4 mx-2 overflow-auto"
      aria-label={"chat history panel"}
    >
      <div
        aria-label="chat history header"
        className="w-full mb-2 flex justify-between items-center"
      >
        <Typography variant="h6" role="heading" aria-level={2}>
          Chat history
        </Typography>
        <div>
          <IconButton
            title={"Clear all chat history"}
            onClick={onShowContextualMenu}
            aria-label={"clear all chat history"}
            role="button"
            id="moreButton"
          >
            <MoreHorizIcon />
          </IconButton>
        </div>
      </div>
      {conversationHistory.error ? (
        <div className="mt-2 flex flex-col items-center">
          <div>
            <Typography variant="caption">
              Error loading chat history
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              Chat history can&apos;t be saved at this time
            </Typography>
          </div>
        </div>
      ) : (
        <ChatHistoryList />
      )}
      {conversationHistory.isLoading && (
        <>
          <div className="mt-4 flex justify-center">
            <div className="mr-2 flex items-center">
              <CircularProgress size={16} />
            </div>
            <Typography variant="caption">Loading chat history</Typography>
          </div>
        </>
      )}
      <Dialog open={showClearAllDialog}>
        <DialogTitle>
          {!clearingError
            ? "Are you sure you want to clear all chat history?"
            : "Error deleting all of chat history"}
        </DialogTitle>
        <DialogContent>
          {!clearingError
            ? "All chat history will be permanently removed."
            : "Please try again. If the problem persists, please contact the site administrator."}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onHideClearAllDialog}>
            {!clearingError
              ? t(commonMessages.cancel)
              : t(commonMessages.close)}
          </Button>
          {!clearingError && (
            <Button variant="contained" onClick={onClearAllChatHistory}>
              Clear All
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Menu
        id="context-menu"
        MenuListProps={{
          "aria-labelledby": "context-menu",
        }}
        anchorEl={ctxMenuAnchorEl}
        open={ctxMenuOpen}
        onClose={onHideContextualMenu}
      >
        <MenuItem
          onClick={() => {
            setCtxMenuAnchorEl(null);
            setShowClearAllDialog(true);
          }}
        >
          <DeleteIcon className="mr-2" />
          Clear all chat history
        </MenuItem>
      </Menu>
    </section>
  );
}
