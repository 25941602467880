import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { RagSource } from "../types";

type ModeWithFile = {
  name: string;
  temperature: number;
  systemPromptAddition: string;
  noInnioContext: boolean;
  documentTypes: string[] | null;
  enabledFunctions: string[] | null;
  attachments?: File[];
  ragSources: RagSource[];
  disableAnimation: boolean;
};

export function useModeCreate(
  options?: UseMutationOptions<unknown, ApiError, ModeWithFile>
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<{ _id: string }, ApiError, ModeWithFile>(
    ({
      name,
      temperature,
      systemPromptAddition: system_prompt_addition,
      noInnioContext: no_innio_context,
      documentTypes: document_types,
      enabledFunctions: enabled_functions,
      attachments,
      ragSources,
      disableAnimation: disable_animation,
    }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("temperature", temperature.toString());
      formData.append("system_prompt_addition", system_prompt_addition);
      formData.append("no_innio_context", no_innio_context.toString());
      document_types?.forEach((documentType) => {
        formData.append("document_types", documentType);
      });
      enabled_functions?.forEach((enabledFunction) => {
        formData.append("enabled_functions", enabledFunction);
      });
      attachments?.forEach((file) => {
        formData.append("attachments", file);
      });
      ragSources.forEach((ragSource) => {
        formData.append("rag_sources", ragSource.sharepoint_url);
      });
      formData.append("disable_animation", disable_animation.toString());
      return client.fetch(`/api/modes`, {
        method: "POST",
        body: formData,
      });
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
