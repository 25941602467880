import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useModeDelete() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => {
      return client.fetch(`/api/modes/${id}`, {
        method: "DELETE",
      });
    },

    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
    }
  );
}
