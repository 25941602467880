import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions } from "react-query";
import { RagSource } from "../types";

export function useModeRagSourceValidate(
  options?: UseMutationOptions<
    unknown,
    ApiError,
    { modeId: string; ragSource: RagSource }
  >
) {
  const client = useApiClient();
  return useMutation(({ modeId, ragSource }) => {
    return client.fetch<{
      success: boolean;
      error?: string;
      top_level_documents_count?: number;
    }>(`/api/modes/rag-sources/validate?mode_id=${modeId}`, {
      method: "POST",
      body: JSON.stringify(ragSource),
    });
  }, options);
}
