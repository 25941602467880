import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { ModeAccess } from "../types";

export function useModeAccess(modeId?: string) {
  const client = useApiClient();
  return useQuery<ModeAccess>(
    ["mode-access", modeId],
    () => client.fetch(`/api/modes/${modeId}/access`),
    {
      enabled: !!modeId,
    }
  );
}
