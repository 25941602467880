import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export function useDisclaimer() {
  const DISCLAIMER_COOKIE = "Disclaimer";
  const [cookies, setCookie] = useCookies([DISCLAIMER_COOKIE]);
  const [accepted, setAccepted] = useState(Boolean(cookies.Disclaimer));
  const accept = () => {
    setCookie(DISCLAIMER_COOKIE, true, { path: "/", maxAge: 3600 * 24 * 7 });
    setAccepted(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const accepted = Boolean(cookies.Disclaimer);
      setAccepted(accepted);
    }, 1000);
    return () => clearInterval(interval);
  }, [cookies.Disclaimer]);

  return { accepted, accept };
}

export const Disclaimer = () => {
  const { accepted, accept } = useDisclaimer();

  return (
    <Dialog open={!accepted}>
      <DialogTitle>Terms of Use</DialogTitle>
      <DialogContent>
        <Typography className="font-bold">1. Introduction</Typography>
        <Typography variant="body2">
          This chat utilizes GPT models provided by OpenAI and is hosted within
          INNIO's premises – this means that you may provide confidential
          information to the chatbot. Do not provide personal information to the
          chat and use the chatbot for business purposes only!
        </Typography>
        <Typography className="mt-4 font-bold">2. INNIO's Data Use</Typography>
        <Typography variant="body2">
          While you may provide confidential information to the chatbot, please
          be aware that INNIO reserves the right to use the data of the chat
          history for further training of the chat model. This will be done
          without linkage to personal information. The chat history is also
          stored for your convenience and can be deleted by you. By performing
          the deletion, your chat history will also be deleted from INNIO’s
          premises. If you prefer a chatbot where INNIO does not use the chat
          history for training purposes, consider using GPT for INNIO.
        </Typography>
        <Typography className="mt-4 font-bold">3. Responsible Use</Typography>
        <Typography variant="body2">
          When using the chatbot, please note that INNIO’s applicable policies
          (e.g. INNIO’s Code of Conduct) and procedures still apply. This chat
          must be used for legitimate business purposes and particularly must
          not be used in a discriminatory manner. The chatbot is designed to
          assist, but it should not replace human decision-making. Always review
          the chatbot’s output as if you had created the content yourself –
          there must always be a human in the loop.
        </Typography>
        <Typography className="mt-4 font-bold">
          4. Source Verification
        </Typography>
        <Typography variant="body2">
          Verify source of the chatbot’s output, veracity of source, including
          indication of source with publication particularly when used for
          external purposes (e.g. press releases). When using AI for software
          fixes, confirm the source of the fix and review if it involves
          open-source, freeware, or commercial usage terms that might pose a
          risk to INNIO's proprietary rights or violate third-party terms.
        </Typography>
        <Typography variant="body1" className="mt-8">
          By clicking "Accept", you agree to these terms of use.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={accept}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
