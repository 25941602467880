import DescriptionIcon from "@mui/icons-material/Description";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  lighten,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { ConversationMessage } from "../../types";
import { CopyToClipboardButton } from "../common/CopyToClipboardButton";
import { useAppState } from "../../state/App";
import { RegenerateButton } from "../common/RegenerateButton";

type Props = {
  answer: ConversationMessage;
  onRegenerate?: () => void;
};

export function Question({ answer }: Props) {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  return (
    <div className="md:max-w-[80%] flex flex-col items-end group relative">
      <Card
        className="relative"
        variant="outlined"
        sx={{
          "&.MuiCard-root": {
            backgroundColor: lighten(
              theme.palette.primary.main,
              theme.palette.mode === "dark" ? 0 : 0.95
            ),
          },
        }}
      >
        <CardContent
          className="relative max-h-80 overflow-y-auto"
          sx={{
            pt: 0,
            pb:
              (answer.attachments?.length ?? 0) > 0
                ? "0 !important"
                : "0rem !important",
          }}
        >
          <div className="flex-grow py-4 whitespace-pre-line">
            {answer.content}
          </div>
          {/* Note: for debugging */}
          {/* {answer.recording && ( */}
          {/*   <div> */}
          {/*     <audio */}
          {/*       controls */}
          {/*       src={URL.createObjectURL(answer.recording)} */}
          {/*     ></audio> */}
          {/*   </div> */}
          {/* )} */}
          {answer.attachments && answer.attachments.length > 0 && (
            <>
              <Divider />
              <List dense>
                {answer.attachments.map(({ _id, file_name }, id) => (
                  <div
                    key={id}
                    className="my-2 flex flex-wrap flex-grow items-center"
                  >
                    <Link
                      href={`/api/history/conversation/${answer.conversation_id}/message/${answer._id}/attachment/${_id}`}
                      target="_blank"
                      rel="noopener"
                      underline="none"
                      sx={{
                        color: isDark
                          ? theme.palette.text.primary
                          : theme.palette.primary.main,
                        pointerEvents: !_id ? "none" : "auto",
                        opacity: !_id ? 0.5 : 1,
                      }}
                    >
                      <ListItem>
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary={file_name} />
                      </ListItem>
                    </Link>
                  </div>
                ))}
              </List>
            </>
          )}
        </CardContent>
      </Card>
      <div className="absolute bottom-[-3rem]">
        {answer.content.length > 20 && (
          <CardActions className="flex justify-end invisible group-hover:visible">
            <QuestionActions answer={answer} />
          </CardActions>
        )}
      </div>
    </div>
  );
}

export const QuestionActions = ({ answer }: Props) => {
  const {
    actions: { setQuestion },
  } = useAppState();

  const regenerate = () => {
    setQuestion(answer.content);
  };

  return (
    <div className="flex justify-center">
      <CopyToClipboardButton size="small" text={answer.content} />
      <RegenerateButton size="small" onClick={regenerate} />
    </div>
  );
};
