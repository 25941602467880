import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { Document } from "../types";

export function useKBDocuments() {
  const client = useApiClient();
  return useQuery("knoledge-base", () =>
    client.fetch<Document[]>("/api/knowledge-base/documents")
  );
}

export function useKBDocument(id?: string) {
  const client = useApiClient();
  return useQuery(
    ["knoledge-base", id],
    () => client.fetch<Document>(`/api/knowledge-base/documents/${id}`),
    { enabled: !!id }
  );
}
