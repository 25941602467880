import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Tooltip,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const messages = defineMessages({
  disableAnimation: {
    id: "animation.disableAnimation",
    defaultMessage: "Disable Animation",
  },
  tooltip: {
    id: "animation.tooltip",
    defaultMessage:
      "Animation during answer generation makes the typing more flowing. If disabled, chunks of the answer are coming in as generated.",
  },
});

export function DisableAnimationSelector(props: Props) {
  return (
    <>
      <DisableAnimationHeading />
      <DisableAnimationSwitch {...props} />
    </>
  );
}

export function DisableAnimationHeading() {
  const { formatMessage: t } = useIntl();
  return (
    <div className="flex items-center">
      <Typography variant="h6" id="input-slider">
        {t(messages.disableAnimation)}
      </Typography>
      &nbsp;
      <Tooltip title={t(messages.tooltip)}>
        <HelpOutlineIcon fontSize="small" />
      </Tooltip>
    </div>
  );
}

type Props = {
  disableAnswerAnimation: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export function DisableAnimationSwitch({
  disableAnswerAnimation,
  onChange,
  disabled,
}: Props) {
  const { formatMessage: t } = useIntl();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={disableAnswerAnimation}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
          />
        }
        label={t(messages.disableAnimation)}
      />
    </FormGroup>
  );
}
