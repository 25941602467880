import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import { defineMessages, useIntl } from "react-intl";

enum Temperature {
  Strict = 0.2,
  Balanced = 0.5,
  Creative = 0.8,
}

export const defaultTemperature = Temperature.Strict;

const temperatureLabels = defineMessages({
  [Temperature.Strict]: {
    id: "temperature.strict",
    defaultMessage: "Strict",
  },
  [Temperature.Balanced]: {
    id: "temperature.balanced",
    defaultMessage: "Balanced",
  },
  [Temperature.Creative]: {
    id: "temperature.creative",
    defaultMessage: "Creative",
  },
});

const messages = defineMessages({
  temperature: {
    id: "temperature",
    defaultMessage: "Conversation Style",
  },
});

const supportedTemperatures = [
  {
    value: 0.2,
    label: temperatureLabels[Temperature.Strict],
  },
  {
    value: 0.5,
    label: temperatureLabels[Temperature.Balanced],
  },
  {
    value: 0.8,
    label: temperatureLabels[Temperature.Creative],
  },
];

export function getTemperatureLabel(temperature: number) {
  return supportedTemperatures.find((t) => t.value === temperature)?.label;
}

export function TemperatureSelector(props: Props) {
  return (
    <>
      <TemperatureHeading />
      <TemperatureSlider {...props} />
    </>
  );
}

export function TemperatureHeading() {
  const { formatMessage: t } = useIntl();
  return (
    <Typography variant="h6" id="input-slider" gutterBottom>
      {t(messages.temperature)}
    </Typography>
  );
}

type Props = {
  temperature?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

export function TemperatureSlider({ temperature, onChange, disabled }: Props) {
  const { formatMessage: t } = useIntl();

  const getTemperatureName = (value: number) => {
    if (value in Temperature) {
      return t(temperatureLabels[value as Temperature]);
    }
    return "";
  };

  const handleChange = (_: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  const value =
    temperature &&
    supportedTemperatures.map(({ value }) => value).includes(temperature)
      ? temperature
      : defaultTemperature;

  return (
    <>
      <Box sx={{ width: "auto", mx: 4 }}>
        <Slider
          aria-label="Restricted values"
          defaultValue={0.8}
          valueLabelDisplay="off"
          getAriaValueText={(value) => getTemperatureName(value)}
          step={null}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          marks={supportedTemperatures.map(({ value, label }) => ({
            value,
            label: t(label),
          }))}
          min={0.2}
          max={0.8}
        />
      </Box>
    </>
  );
}
