import React, { FC } from "react";
import { useAppState } from "../../state/App";
import { GroupedChatHistory } from "./ChatHistoryList";
import { Conversation } from "../../types";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useConversationDelete } from "../../hooks/useConversationDelete";
import { useConversationUpdate } from "../../hooks/useConversationUpdate";
import { commonMessages } from "../../commonMessages";
import { useIntl } from "react-intl";

interface ChatHistoryListItemCellProps {
  item: Conversation;
  onSelect: (item: Conversation | null) => void;
}

interface ChatHistoryListItemGroupsProps {
  groupedChatHistory: GroupedChatHistory[];
}

const formatMonth = (month: string) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [monthName, yearString] = month.split(" ");
  const year = parseInt(yearString);

  if (year === currentYear) {
    return monthName;
  } else {
    return month;
  }
};

export const ChatHistoryListItemCell: FC<ChatHistoryListItemCellProps> = ({
  item,
  onSelect,
}) => {
  const { formatMessage: t } = useIntl();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [errorDelete, setErrorDelete] = useState<string | undefined>(undefined);
  const [renameLoading, setRenameLoading] = useState(false);
  const [errorRename, setErrorRename] = useState<string | undefined>(undefined);
  const { mutateAsync: deleteConversation, isLoading: isDeleting } =
    useConversationDelete();
  const { mutateAsync: updateConversation, isLoading: isUpdating } =
    useConversationUpdate();

  const {
    state: { currentConversationId, isGenerating },
    actions: {
      setCurrentConversationId,
      setMessages,
      setCurrentTTSUrl,
      setIsPlaying,
    },
  } = useAppState();
  const isSelected = item?._id === currentConversationId;

  const onDelete = async () => {
    try {
      await deleteConversation(item._id);
      setCurrentConversationId(undefined);
      setIsHovered(false);
      setMessages([]);
    } catch (e) {
      setErrorDelete("Error: could not delete item");
    } finally {
      setShowDeleteDialog(false);
    }
  };

  const onEdit = () => {
    setEdit(true);
    setEditTitle(item?.title);
  };

  const handleSelectItem = () => {
    onSelect(item);
    setCurrentTTSUrl(undefined);
    setIsPlaying(false);
    setCurrentConversationId(item._id);
  };

  const handleSaveEdit = async () => {
    if (errorRename || renameLoading) {
      return;
    }

    if (editTitle == item.title) {
      setErrorRename("Error: Enter a new title to proceed.");
      setTimeout(() => {
        setErrorRename(undefined);
      }, 5000);
      return;
    }

    try {
      setRenameLoading(true);
      await updateConversation({
        id: item._id,
        title: editTitle,
      });
      setErrorRename(undefined);
      setEditTitle("");
      setEdit(false);
    } catch (e) {
      setErrorRename("Error: could not rename item");
    } finally {
      setRenameLoading(false);
    }
  };

  const chatHistoryTitleOnChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (e) => {
    setEditTitle(e.target.value);
  };

  const cancelEditTitle = () => {
    setEdit(false);
    setEditTitle("");
  };

  const handleKeyPressEdit:
    | React.KeyboardEventHandler<HTMLDivElement>
    | undefined = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return handleSaveEdit();
    }
    if (e.key === "Escape") {
      cancelEditTitle();
      return;
    }
  };

  return (
    <div
      role="button"
      key={item._id}
      tabIndex={0}
      aria-label="chat history item"
      className="px-4 py-2"
      onClick={() => {
        if (!isGenerating) {
          handleSelectItem();
        }
      }}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      style={{
        backgroundColor:
          isSelected || isHovered ? theme.palette.action.hover : "transparent",
        cursor: isGenerating ? "not-allowed" : "pointer",
      }}
    >
      {edit ? (
        <div className="w-full">
          <form
            aria-label="edit title form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveEdit();
            }}
          >
            <div className="flex w-full justify-between items-center">
              <div>
                <TextField
                  size="small"
                  autoFocus
                  value={editTitle}
                  placeholder={item.title}
                  onChange={chatHistoryTitleOnChange}
                  onKeyDown={handleKeyPressEdit}
                  disabled={isUpdating || errorRename ? true : false}
                  error={Boolean(errorRename)}
                  helperText={errorRename}
                />
              </div>
              {editTitle && (
                <div className="flex">
                  <IconButton
                    role="button"
                    disabled={isUpdating || errorRename !== undefined}
                    onKeyDown={(e) =>
                      e.key === " " || e.key === "Enter"
                        ? handleSaveEdit()
                        : null
                    }
                    onClick={handleSaveEdit}
                    aria-label="confirm new title"
                    color="success"
                    size="small"
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    role="button"
                    disabled={isUpdating || errorRename !== undefined}
                    onKeyDown={(e) =>
                      e.key === " " || e.key === "Enter"
                        ? cancelEditTitle()
                        : null
                    }
                    onClick={() => cancelEditTitle()}
                    aria-label="cancel edit title"
                    color="error"
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="flex justify-between items-center w-full h-8">
          <div className="truncate">
            <Typography noWrap>{item?.title}</Typography>
          </div>
          {(isSelected || isHovered) && (
            <div className="flex">
              <IconButton
                size="small"
                title="Delete"
                disabled={isGenerating}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteDialog(true);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                title="Edit"
                disabled={isGenerating}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
                onKeyDown={(e) => (e.key === " " ? onEdit() : null)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
      )}
      {errorDelete && (
        <Typography variant="caption" color="error">
          {errorDelete}
        </Typography>
      )}
      <Dialog open={showDeleteDialog}>
        <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        <DialogContent>
          The history of this chat session will permanently removed.
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteDialog(false);
            }}
            disabled={isDeleting}
          >
            {t(commonMessages.cancel)}
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            disabled={isDeleting}
          >
            {t(commonMessages.delete)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ChatHistoryListItemGroups: React.FC<
  ChatHistoryListItemGroupsProps
> = ({ groupedChatHistory }) => {
  const [, setSelectedItem] = useState<Conversation | null>(null);

  const handleSelectHistory = (item?: Conversation) => {
    if (item) {
      setSelectedItem(item);
    }
  };

  return (
    <>
      {groupedChatHistory.map(
        (group) =>
          group.entries.length > 0 && (
            <div key={group.month}>
              <div>
                <Typography>{formatMonth(group.month)}</Typography>
              </div>
              {group.entries.map((entry) => (
                <ChatHistoryListItemCell
                  key={entry._id}
                  item={entry}
                  onSelect={() => handleSelectHistory(entry)}
                />
              ))}
              <Divider className="my-4" />
            </div>
          )
      )}
    </>
  );
};
