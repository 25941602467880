import React, {
  createContext,
  useReducer,
  ReactNode,
  useMemo,
  Reducer,
  useContext,
} from "react";
import { ConversationMessage } from "../types";

export type AppState = {
  isChatHistoryOpen: boolean;
  currentConversationId?: string;
  question: string;
  isGenerating: boolean;
  hasError: boolean;
  messages: ConversationMessage[];
  modeId?: string;
  currentTTSUrl?: string;
  isPlaying: boolean;
  autoPlayNotAllowed: boolean;
};

export type Action =
  | { type: "SET_CHAT_HISTORY"; payload: boolean }
  | { type: "SET_CURRENT_CONVERSATION_ID"; payload?: string }
  | { type: "SET_QUESTION"; payload: string }
  | { type: "SET_IS_GENERATING"; payload: boolean }
  | { type: "SET_HAS_ERROR"; payload: boolean }
  | { type: "SET_MESSAGES"; payload: ConversationMessage[] }
  | { type: "ADD_MESSAGE"; payload: ConversationMessage }
  | { type: "ADD_MESSAGES"; payload: ConversationMessage[] }
  | { type: "DELETE_MESSAGE"; payload: ConversationMessage }
  | { type: "UPDATE_MESSAGE"; payload: ConversationMessage }
  | { type: "UPDATE_LAST_MESSAGE"; payload: ConversationMessage }
  | { type: "SET_MODE_ID"; payload?: string }
  | { type: "SET_CURRENT_TTS_URL"; payload?: string }
  | { type: "SET_IS_PLAYING"; payload: boolean }
  | { type: "SET_AUTO_PLAY_NOT_ALLOWED"; payload: boolean };

const initialState: AppState = {
  question: "",
  isChatHistoryOpen: window.matchMedia("(min-width: 1280px)").matches,
  isGenerating: false,
  hasError: false,
  messages: [],
  isPlaying: false,
  autoPlayNotAllowed: false,
};

export const AppStateContext = createContext<{
  state: AppState;
  dispatch?: React.Dispatch<Action>;
  actions: {
    setChatHistory: (isOpen: boolean) => void;
    setCurrentConversationId: (id?: string) => void;
    setQuestion: (question: string) => void;
    setIsGenerating: (isGenerating: boolean) => void;
    setHasError: (hasError: boolean) => void;
    setMessages: (messages: ConversationMessage[]) => void;
    addMessage: (message: ConversationMessage) => void;
    addMessages: (messages: ConversationMessage[]) => void;
    deleteMessage: (message: ConversationMessage) => void;
    updateMessage: (message: ConversationMessage) => void;
    updateLastMessage: (message: ConversationMessage) => void;
    setModeId: (modeId?: string) => void;
    setCurrentTTSUrl: (identifier?: string) => void;
    setIsPlaying: (isPlaying: boolean) => void;
    setAutoPlayNotAllowed: (autoPlayNotAllowed: boolean) => void;
  };
}>({
  state: initialState,
  actions: {
    setChatHistory: () => {},
    setCurrentConversationId: () => {},
    setQuestion: () => {},
    setIsGenerating: () => {},
    setHasError: () => {},
    setMessages: () => {},
    addMessage: () => {},
    addMessages: () => {},
    deleteMessage: () => {},
    updateMessage: () => {},
    updateLastMessage: () => {},
    setModeId: () => {},
    setCurrentTTSUrl: () => {},
    setIsPlaying: () => {},
    setAutoPlayNotAllowed: () => {},
  },
});

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
}

type AppStateProviderProps = {
  children: ReactNode;
};

const STORAGE_KEY = "app-state";
export const withCache =
  (reducer: Reducer<typeof initialState, Action>) =>
  (state: AppState, action: Action) => {
    const stateNew = reducer(state, action);
    const { isChatHistoryOpen, modeId } = stateNew;
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        isChatHistoryOpen,
        modeId,
      })
    );
    return stateNew;
  };

const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "SET_CURRENT_CONVERSATION_ID":
      return { ...state, currentConversationId: action.payload };
    case "SET_QUESTION":
      return { ...state, question: action.payload };
    case "SET_IS_GENERATING":
      return { ...state, isGenerating: action.payload };
    case "SET_CHAT_HISTORY":
      return { ...state, isChatHistoryOpen: action.payload };
    case "SET_HAS_ERROR":
      return { ...state, hasError: action.payload };
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "ADD_MESSAGE":
      return { ...state, messages: [...state.messages, action.payload] };
    case "ADD_MESSAGES":
      return { ...state, messages: [...state.messages, ...action.payload] };
    case "DELETE_MESSAGE": {
      const messages = state.messages.filter(
        (m) => m._id !== action.payload._id
      );
      return { ...state, messages };
    }
    case "UPDATE_MESSAGE": {
      const messages = state.messages.map((m) => {
        if (m._id === action.payload._id) {
          return action.payload;
        }
        return m;
      });
      return { ...state, messages };
    }
    case "UPDATE_LAST_MESSAGE": {
      const messages = state.messages.map((m, i) => {
        if (
          (action.payload.role === "ai" && i === state.messages.length - 1) ||
          (action.payload.role === "human" && i === state.messages.length - 2)
        ) {
          return action.payload;
        }
        return m;
      });
      return { ...state, messages };
    }
    case "SET_MODE_ID":
      return { ...state, modeId: action.payload };
    case "SET_CURRENT_TTS_URL":
      return { ...state, currentTTSUrl: action.payload };
    case "SET_IS_PLAYING":
      return { ...state, isPlaying: action.payload };
    case "SET_AUTO_PLAY_NOT_ALLOWED":
      return { ...state, autoPlayNotAllowed: action.payload };
  }
};
export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  children,
}) => {
  const persistedState = JSON.parse(
    localStorage.getItem(STORAGE_KEY) as string
  );
  const [state, dispatch] = useReducer(withCache(appStateReducer), {
    ...initialState,
    ...persistedState,
  });

  const actions = useMemo(() => {
    return {
      setChatHistory: (isOpen: boolean) =>
        dispatch({ type: "SET_CHAT_HISTORY", payload: isOpen }),
      setCurrentConversationId: (id?: string) =>
        dispatch({ type: "SET_CURRENT_CONVERSATION_ID", payload: id }),
      setQuestion: (question: string) =>
        dispatch({ type: "SET_QUESTION", payload: question }),
      setIsGenerating: (isGenerating: boolean) =>
        dispatch({ type: "SET_IS_GENERATING", payload: isGenerating }),
      setHasError: (hasError: boolean) =>
        dispatch({ type: "SET_HAS_ERROR", payload: hasError }),
      setMessages: (messages: ConversationMessage[]) =>
        dispatch({ type: "SET_MESSAGES", payload: messages }),
      addMessage: (message: ConversationMessage) =>
        dispatch({ type: "ADD_MESSAGE", payload: message }),
      addMessages: (messages: ConversationMessage[]) =>
        dispatch({ type: "ADD_MESSAGES", payload: messages }),
      deleteMessage: (message: ConversationMessage) =>
        dispatch({ type: "DELETE_MESSAGE", payload: message }),
      updateMessage: (message: ConversationMessage) =>
        dispatch({ type: "UPDATE_MESSAGE", payload: message }),
      updateLastMessage: (message: ConversationMessage) =>
        dispatch({ type: "UPDATE_LAST_MESSAGE", payload: message }),
      setModeId: (mode?: string) =>
        dispatch({ type: "SET_MODE_ID", payload: mode }),
      setCurrentTTSUrl: (identifier?: string) =>
        dispatch({ type: "SET_CURRENT_TTS_URL", payload: identifier }),
      setIsPlaying: (isPlaying: boolean) =>
        dispatch({ type: "SET_IS_PLAYING", payload: isPlaying }),
      setAutoPlayNotAllowed: (autoPlayNotAllowed: boolean) =>
        dispatch({
          type: "SET_AUTO_PLAY_NOT_ALLOWED",
          payload: autoPlayNotAllowed,
        }),
    };
  }, [dispatch]);

  return (
    <AppStateContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </AppStateContext.Provider>
  );
};
