import { useGetHelpDialog } from "@myplant-io/lab";
import { useAuth } from "../state/Auth";

export const GetHelpDialog = () => {
  const { user } = useAuth();
  const { render: renderGetHelpDialog } = useGetHelpDialog({
    apiOptions: {
      baseUrl: "/ticket-gateway",
    },
    ticketDefaults: {
      group: "AI Assistant - Level 1 Support",
      feature: "myplant AI:: Assistant",
      reference_link: window.location.href,
      customer: user?.email?.toLocaleLowerCase(),
    },
    attachmentsProps: { enabled: true },
    getTicketLink: (id: number): string => {
      return `https://tickets.myplant.io/#ticket/zoom/${id}`;
    },
  });
  return <>{renderGetHelpDialog()}</>;
};
