import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { EnabledFunction } from "../types";

type EnabledFunctionResponse = {
  name: string;
  short_description?: string;
  requires_innio_context: boolean;
};

export const useFunctions = () => {
  const client = useApiClient();
  return useQuery<EnabledFunction[]>(["functions"], () =>
    client
      .fetch("/api/knowledge-base/function")
      .then((res: EnabledFunctionResponse[]) =>
        res
          .map((doc) => ({
            id: doc.name,
            label: doc.short_description ?? doc.name,
            requiresInnioContext: doc.requires_innio_context,
            selected: true,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      )
  );
};
