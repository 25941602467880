import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { commonMessages } from "../../commonMessages";
import { useModeRagSourceValidate } from "../../hooks/useModeRagSourceValidate";
import { RagSource } from "../../types";

export const messages = defineMessages({
  ragSources: {
    id: "rag.ragSources",
    defaultMessage: "Knowledge Bases",
  },
  tooltip: {
    id: "rag.tooltip",
    defaultMessage:
      "Add documents from Sharepoint to the Assistant. The Assistant searches in those documents to find relevant information to answer the user question. The documents from the added Sharepoint folders are kept up-to-date.",
  },
});

export function RagSourcesHeading({
  onRagSourceAdd,
}: {
  onRagSourceAdd: () => void;
}) {
  const { formatMessage: t } = useIntl();
  return (
    <div className="flex items-center">
      <Typography variant="h6">{t(messages.ragSources)}</Typography>
      &nbsp;
      <Tooltip title={t(messages.tooltip)}>
        <HelpOutlineIcon fontSize="small" />
      </Tooltip>
      <IconButton
        className="ml-2"
        onClick={() => onRagSourceAdd()}
        size="large"
      >
        <AddIcon />
      </IconButton>
    </div>
  );
}

type Props = TextFieldProps & {
  modeId?: string;
  error: boolean;
  helperText?: string;
  loadingChanged?: (loading: boolean) => void;
  validationChanged?: (validationResult: ValidationResult) => void;
} & { ragSource?: RagSource };

type ValidationResult = {
  success?: boolean;
  top_level_documents_count?: number;
  error?: string;
};

export const RagSourceInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      modeId,
      error,
      helperText,
      loadingChanged,
      validationChanged,
      ragSource,
      ...field
    },
    ref
  ) => {
    const { formatMessage: t } = useIntl();
    const {
      mutateAsync: validateRagSource,
      data: validationData,
      isLoading: isRagSourceValidating,
    } = useModeRagSourceValidate();

    const onBlur:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined = (e) => {
      field.onBlur?.(e);
      const validate = async () => {
        try {
          loadingChanged?.(true);
          const r = await validateRagSource({
            modeId: modeId ?? "",
            ragSource: {
              _id: "-1",
              sharepoint_url: field.value as string,
              status: null,
            },
          });
          validationChanged?.(r);
          loadingChanged?.(false);
        } catch (error) {
          const r = {
            success: false,
            error:
              error instanceof Error
                ? error.message
                : "An error occurred during validation",
          };
          validationChanged?.(r);
          loadingChanged?.(false);
        }
      };
      validate();
    };

    const { documents_count, ingesting } = ragSource?.status || {};
    const count = validationData?.top_level_documents_count ?? documents_count;
    const showDocumentsCount =
      validationData?.success || typeof count === "number";

    return (
      <TextField
        {...field}
        ref={ref}
        fullWidth
        label="Sharepoint URL"
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        InputProps={{
          sx: {
            "& .MuiOutlinedInput-input": {
              textOverflow: "ellipsis",
            },
          },
          endAdornment: (
            <>
              {isRagSourceValidating ? (
                <CircularProgress size={20} className="mx-4" />
              ) : showDocumentsCount ? (
                <Chip
                  className="ml-2"
                  color={ingesting ? "default" : "success"}
                  label={t(
                    ingesting
                      ? commonMessages.ingesting
                      : commonMessages.documentsNr,
                    {
                      count,
                    }
                  )}
                />
              ) : null}
            </>
          ),
        }}
      />
    );
  }
);

RagSourceInput.displayName = "RagSourceInput";
