import {
  Button,
  CircularProgress,
  DialogContentText,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@myplant-io/mui";
import { commonMessages } from "../../commonMessages";
import { Document } from "../../types";
import { useKBDocument } from "../../hooks/useKnowledgeBase";

type Props = {
  open: boolean;
  onClose: () => void;
  document?: Document;
};

const messages = defineMessages({
  title: {
    id: "documentDetail.title",
    defaultMessage: "Chunks",
  },
});

export function DocumentDetailDialog({ open, onClose, document }: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: documentDetails, isLoading } = useKBDocument(document?.id);
  const { formatMessage: t } = useIntl();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isSmall}
      maxWidth="lg"
    >
      <DialogTitle onClose={onClose}>{t(messages.title)}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          documentDetails?.chunks.map(({ id, text }) => (
            <div key={id} className="flex flex-col">
              <DialogContentText>{text}</DialogContentText>
              <Divider className="my-2" />
            </div>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t(commonMessages.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
