import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { ModeUser } from "../types";

export function useModeAvailableUsers() {
  const client = useApiClient();
  return useQuery<ModeUser[]>("available-users", () =>
    client.fetch("/api/modes/available-users")
  );
}
