import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { Conversation } from "../types";

export function useConversationHistory() {
  const client = useApiClient();
  return useQuery<Conversation[]>("conversation", () =>
    client.fetch("/api/history/conversation")
  );
}
