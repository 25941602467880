import { useState, useMemo } from "react";
import { useKBDocuments } from "../../hooks/useKnowledgeBase";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { defineMessages, useIntl } from "react-intl";
import { useDataGridPremium } from "@myplant-io/mui";
import { AppToolbar } from "@myplant-io/app-components";
import { DocumentDetailDialog } from "./DocumentDetailDialog";
import { Document } from "../../types";
import { IconButton, Input, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import { debounce } from "lodash";

const messages = defineMessages({
  header: {
    id: "knowledgeBase.header",
    defaultMessage: "Knowledge Base",
  },
  name: {
    id: "knowledgeBase.column.name",
    defaultMessage: "Name",
  },
  documentType: {
    id: "knowledgeBase.column.documentType",
    defaultMessage: "Document Type",
  },
  link: {
    id: "knowledgeBase.column.link",
    defaultMessage: "Link",
  },
  chunks: {
    id: "knowledgeBase.column.chunks",
    defaultMessage: "Chunks",
  },
  search: {
    id: "knowledgeBase.search",
    defaultMessage: "Search",
  },
});

const useDebouncedPickerQuery = (timeout = 350) => {
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");

  const debouncedSetQuery = useMemo(
    () => debounce(setQuery, timeout),
    [timeout]
  );
  const updateQuery = (query: string) => {
    debouncedSetQuery(query);
    setInputValue(query);
  };

  return {
    query,
    setQuery: updateQuery,
    inputValue,
  };
};

export default function KnowledgeBase() {
  const { formatMessage: t } = useIntl();
  const searchQuery = useDebouncedPickerQuery();
  const { data: allDocuments, isLoading } = useKBDocuments();
  const [selectedDocument, setSelectedDocument] = useState<
    Document | undefined
  >();

  const columns: GridColDef<Document>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(messages.name),
        flex: 1,
      },
      {
        field: "document_type",
        headerName: t(messages.documentType),
        width: 150,
      },
      {
        field: "chunks",
        headerName: t(messages.chunks),
        width: 150,
      },
      {
        field: "link",
        headerName: t(messages.link),
        width: 150,
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            key="link"
            icon={<LaunchIcon />}
            label={t(messages.link)}
            onClick={() => {
              window.open(
                `/api/knowledge-base/documents/${params.id}/link`,
                "_blank"
              );
            }}
          />,
        ],
      },
    ],
    [t]
  );

  const apiRef = useGridApiRef();
  const { gridProps } = useDataGridPremium({
    apiRef,
    gridStateId: "KNOWLEDGE_BASE_GRID_STATE",
    columns,
    autoPageSize: true,
    initialState: {
      sorting: {
        sortModel: [
          {
            field: "name",
            sort: "asc",
          },
        ],
      },
      columns: {
        columnVisibilityModel: {
          chunks: false,
        },
      },
    },
  });

  const documents = useMemo(() => {
    return (
      allDocuments
        ?.filter(({ name }) =>
          name.toLowerCase().includes(searchQuery.query.toLowerCase())
        )
        ?.map((document) => ({
          ...document,
          name: document.name || "N/A",
        })) ?? []
    );
  }, [allDocuments, searchQuery.query]);

  return (
    <>
      <AppToolbar title={t(messages.header)} elevate />
      <div className="flex flex-col flex-1 p-4 sm:px-6" role="main">
        <div className="flex flex-col h-full flex-grow max-w-5xl m-auto w-full ">
          <div className="flex justify-end my-4">
            <Input
              data-testid="user-management-search"
              id="search"
              placeholder={t(messages.search)}
              value={searchQuery.inputValue}
              onChange={(e) => searchQuery.setQuery(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={searchQuery.inputValue !== "" ? false : true}
                    aria-label="clear search"
                    onClick={() => searchQuery.setQuery("")}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="flex flex-col h-full flex-grow max-w-5xl m-auto w-full min-h-[400px]">
            <AutoSizer>
              {({ height, width }: Size) => (
                <div style={{ height, width }}>
                  <DataGridPremium<Document>
                    {...gridProps}
                    loading={isLoading}
                    rows={documents}
                    pagination
                    disableRowSelectionOnClick
                    onRowClick={(args) => setSelectedDocument(args.row)}
                  />
                </div>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>

      <DocumentDetailDialog
        open={!!selectedDocument}
        onClose={() => setSelectedDocument(undefined)}
        document={selectedDocument}
      />
    </>
  );
}
