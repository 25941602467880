import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AppLandingPage } from "@myplant-io/app-landing-page";
import { AILogoPos, AILogoNeg, AILogoMonoNeg } from "@myplant-io/logos";
import { RouteNames } from "../RouteNames";
import { useAuth } from "../state/Auth";
import { useSnackbar } from "@myplant-io/snackbar";
import { defineMessages, useIntl } from "react-intl";
import { usePublicAppByName } from "@myplant-io/hooks";

const messages = defineMessages({
  unauthorized: {
    id: "upstream.error.not-allowed",
    defaultMessage: "You are not allowed to access this page.",
  },
});

export function LandingPage() {
  const { formatMessage, locale } = useIntl();
  const authCtx = useAuth();
  const navigate = useNavigate();
  const { showErrorMessage } = useSnackbar();

  const { data, isLoading } = usePublicAppByName({
    wsBaseUrl: "/",
    appName: "WEB_ASSISTANT",
    language: locale,
  });
  const login = () => {
    navigate(RouteNames.LOGIN);
  };

  useEffect(() => {
    if (
      authCtx.isFetched &&
      !authCtx.isLoading &&
      !authCtx.isError &&
      !authCtx.user?.username
    ) {
      showErrorMessage({
        message: formatMessage(messages.unauthorized),
        disableClickAway: true,
      });
    }
  }, [
    authCtx.isError,
    authCtx.isFetched,
    authCtx.isLoading,
    authCtx.user,
    formatMessage,
    navigate,
    showErrorMessage,
  ]);

  if (authCtx.user?.username) {
    return <Navigate to={RouteNames.CHAT} />;
  }

  return (
    <AppLandingPage
      loading={isLoading || !authCtx.isFetched}
      title={data?.name ?? ""}
      subTitle={data?.shortDescription ?? ""}
      productSuiteLogos={{
        mobileLight: <AILogoPos />,
        mobileDark: <AILogoNeg />,
        onBackdrop: <AILogoMonoNeg />,
        onBackdropDark: <AILogoMonoNeg />,
      }}
      onLogin={login}
    />
  );
}
