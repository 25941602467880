import { ChatHistoryListItemGroups } from "./ChatHistoryListItem";
import { Conversation } from "../../types";
import { Typography } from "@mui/material";
import { useConversationHistory } from "../../hooks/useConversationHistory";

interface ChatHistoryListProps {}

export interface GroupedChatHistory {
  month: string;
  entries: Conversation[];
}

const groupByMonth = (entries: Conversation[]) => {
  const groups: GroupedChatHistory[] = [{ month: "Recent", entries: [] }];
  const currentDate = new Date();

  entries.forEach((entry) => {
    const date = new Date(entry.updated);
    const daysDifference =
      (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
    const monthYear = date.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    const existingGroup = groups.find((group) => group.month === monthYear);

    if (daysDifference <= 7) {
      groups[0].entries.push(entry);
    } else {
      if (existingGroup) {
        existingGroup.entries.push(entry);
      } else {
        groups.push({ month: monthYear, entries: [entry] });
      }
    }
  });

  groups.sort((a, b) => {
    // Check if either group has no entries and handle it
    if (a.entries.length === 0 && b.entries.length === 0) {
      return 0; // No change in order
    } else if (a.entries.length === 0) {
      return 1; // Move 'a' to a higher index (bottom)
    } else if (b.entries.length === 0) {
      return -1; // Move 'b' to a higher index (bottom)
    }
    const dateA = new Date(a.entries[0].created);
    const dateB = new Date(b.entries[0].created);
    return dateB.getTime() - dateA.getTime();
  });

  groups.forEach((group) => {
    group.entries.sort((a, b) => {
      const dateA = new Date(a.created);
      const dateB = new Date(b.created);
      return dateB.getTime() - dateA.getTime();
    });
  });

  return groups;
};

const ChatHistoryList: React.FC<ChatHistoryListProps> = () => {
  const { data: chatHistory } = useConversationHistory();

  let groupedChatHistory;
  if (chatHistory && chatHistory.length > 0) {
    groupedChatHistory = groupByMonth(chatHistory);
  } else {
    return (
      <div className="mt-4 flex justify-center">
        <Typography
          style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}
        >
          <span>No chat history.</span>
        </Typography>
      </div>
    );
  }

  return <ChatHistoryListItemGroups groupedChatHistory={groupedChatHistory} />;
};

export default ChatHistoryList;
