import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  name: {
    id: "common.name",
    defaultMessage: "Name",
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Cancel",
  },
  close: {
    id: "common.close",
    defaultMessage: "Close",
  },
  delete: {
    id: "common.delete",
    defaultMessage: "Delete",
  },
  submit: {
    id: "common.submit",
    defaultMessage: "Submit",
  },
  addUser: {
    id: "common.addUser",
    defaultMessage: "Add User",
  },
  save: {
    id: "common.save",
    defaultMessage: "Save",
  },
  import: {
    id: "common.import",
    defaultMessage: "Import",
  },
  defaultMode: {
    id: "common.defaultMode",
    defaultMessage: "All INNIO Content",
  },
  noInnioMode: {
    id: "common.noInnioMode",
    defaultMessage: "No INNIO Content",
  },
  chatRequestEntityTooLarge: {
    id: "common.request_entity_too_large",
    defaultMessage: "Request Entity Too Large",
  },
  chatRequestTooManyTokens: {
    id: "common.request_too_many_tokens",
    defaultMessage: "Request Exceeds Token Limit",
  },
  newUpdateAvailable: {
    id: "common.new_update_available",
    defaultMessage: "New version available. Click on reload to update.",
  },
  reload: {
    id: "common.reload",
    defaultMessage: "Reload",
  },
  documentsNr: {
    id: "common.documents-nr",
    defaultMessage:
      "{count, plural, one {{count} Document} other {{count} Documents}}",
  },
  ingesting: {
    id: "common.ingesting",
    defaultMessage:
      "{count, plural, =0 {Ingesting... ({count} Documents)} one {Ingesting... ({count} Document)} other {Ingesting... ({count} Documents)}}",
  },
  readonly: {
    id: "common.readonly",
    defaultMessage: "Readonly",
  },
  canEdit: {
    id: "common.canEdit",
    defaultMessage: "Can Edit",
  },
  selectAll: {
    id: "common.selectAll",
    defaultMessage: "Select All",
  },
});
