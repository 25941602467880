import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useModeAttachmentsDelete() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({
      modeId,
      attachmentIds,
    }: {
      modeId: string;
      attachmentIds: string[];
    }) => {
      return Promise.all(
        attachmentIds.map((attachmentId) =>
          client.fetch(`/api/modes/${modeId}/attachments/${attachmentId}`, {
            method: "DELETE",
          })
        )
      );
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
    }
  );
}
