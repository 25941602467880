import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

export function useModeRagSourcesDelete(
  options?: UseMutationOptions<
    unknown,
    ApiError,
    { modeId: string; ragSourceIds: string[] }
  >
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ modeId, ragSourceIds }) => {
      return Promise.all(
        ragSourceIds.map((ragSourceId) =>
          client.fetch(`/api/modes/${modeId}/rag-sources/${ragSourceId}`, {
            method: "DELETE",
          })
        )
      );
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
