import { useRef, useLayoutEffect, Fragment, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { ConversationMessage } from "../../types";
import { Answer, ErrorAnswer } from "../../components/Answer/Answer";
import { QuestionInput } from "../../components/QuestionInput/QuestionInput";
import { useAppState } from "../../state/App";
import {
  Button,
  ClickAwayListener,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  Tooltip,
} from "@mui/material";
import { useConversation } from "../../hooks/useConversation";
import {
  EventStreamContentType,
  fetchEventSource,
  FetchEventSourceInit,
} from "@microsoft/fetch-event-source";
import { useQueryClient } from "react-query";
import { useSnackbar } from "@myplant-io/snackbar";
import { usePrevious } from "../../hooks/usePrevious";
import { Question } from "../../components/Question/Question";
import { Splash } from "./Splash";
import { defineMessages, useIntl } from "react-intl";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { ModeMenu } from "../../components/common/ModeMenu";
import { commonMessages } from "../../commonMessages";

const drawerWidth = 300;

type GenerateRequest = {
  mode_id?: string;
  message?: string;
  session_id?: string;
  files?: File[];
  recording?: Blob;
};

type ChatResponseDocument = {
  document_id: string;
  name: string;
  content: string;
  has_source_link: boolean;
};

type ChatResponse = {
  session_id: string;
  type: "context" | "start" | "streaming" | "end" | "error" | "transcript";
  value?: string;
  function_value?: string;
  calling_function?: boolean;
  sources?: ChatResponseDocument[];
  message_id?: string;
  standalone_question?: string;
  no_innio_context?: boolean;
  mode_id?: string;
  mode_name?: string;
  error_code?: number;
  audio_url?: string;
};

const intlMessages = defineMessages({
  chatError: {
    id: "chat.error",
    defaultMessage: "An unrecoverable error occurred. Please try again later.",
  },
  scrollToBottom: {
    id: "chat.scrollToBottom",
    defaultMessage: "Scroll to Bottom",
  },
});

const generate = async (
  payload: GenerateRequest,
  abortSignal: AbortSignal,
  options?: FetchEventSourceInit
): Promise<void | Response> => {
  const formData = new FormData();
  if (payload.mode_id) {
    formData.append("mode_id", payload.mode_id);
  }
  if (payload.message) {
    formData.append("message", payload.message);
  }
  formData.append("session_id", payload.session_id ?? "");
  if (payload.files) {
    payload.files.forEach((file) => {
      formData.append("files", file);
    });
  }
  if (payload.recording) {
    formData.append(
      "recording",
      new File([payload.recording], "recording.wav")
    );
  }
  const response = fetchEventSource("/api/generate/chat_form_sse", {
    method: "POST",
    body: formData,
    signal: abortSignal,
    openWhenHidden: true,
    onerror: (e) => {
      // rethrow error to assure the generation is never retried
      throw e;
    },
    ...options,
  })
    .then((res) => {
      return res;
    })
    .catch(() => {
      console.error("There was an issue fetching your data.");
      return new Response();
    });
  return response;
};

const initialResponse = {
  content: "",
  function_content: "",
  conversation_id: "",
  created: "",
  rating: -2,
  role: "ai" as const,
  sources: [],
  updated: "",
  _id: "-1",
};

const checkScrolledBottom = (container: HTMLElement) => {
  return container.scrollHeight - container.scrollTop <= container.clientHeight;
};

export default function Chat() {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [isScrolledBottom, setIsScrolledBottom] = useState(true);
  const { formatMessage: t } = useIntl();
  const { showErrorMessage } = useSnackbar();
  const {
    state: {
      isChatHistoryOpen,
      currentConversationId,
      hasError,
      isGenerating,
      messages,
      modeId: selectedModeId,
    },
    actions: {
      setChatHistory,
      setIsGenerating,
      setCurrentConversationId,
      addMessages,
      setMessages,
      updateLastMessage,
      setHasError,
      setCurrentTTSUrl,
      setIsPlaying,
    },
  } = useAppState();
  const prevMessages = usePrevious(messages);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const abortFuncs = useRef([] as AbortController[]);
  const queryClient = useQueryClient();

  useConversation(currentConversationId, function (data) {
    if (!isGenerating) {
      setMessages(data);
    }
  });

  useLayoutEffect(() => {
    if (
      messages &&
      messages.length > 0 &&
      prevMessages &&
      prevMessages.length !== messages.length
    ) {
      chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, prevMessages]);

  useEffect(() => {
    const onResize = () => {
      if (!chatContainerRef.current) {
        return;
      }
      setIsScrolledBottom(checkScrolledBottom(chatContainerRef.current));
    };

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setIsGenerating(false);
  };

  const handleError = (_message?: string) => {
    stopGenerating();
    setHasError(true);
    const message = _message ?? t(intlMessages.chatError);
    showErrorMessage({
      message,
      action: "Reload",
      disableClickAway: true,
      handleAction: () => {
        location.reload();
      },
    });
  };

  const makeGenerateRequest = async ({
    modeId,
    message,
    files,
    sessionId,
    recording,
  }: {
    modeId?: string;
    message?: string;
    files?: File[];
    sessionId?: string;
    recording?: Blob;
  }) => {
    setIsGenerating(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);
    addMessages([
      {
        role: "human",
        content: message ?? "...",
        function_content: "",
        conversation_id: "",
        created: "",
        rating: 0,
        sources: [],
        updated: "",
        _id: "",
        attachments: files?.map(({ name }) => ({
          _id: "",
          mime_type: "",
          file_name: name,
        })),
        recording,
      },
      initialResponse,
    ]);

    let transcriptText = "";
    let runningText = "";
    let runningFunctionText = "";
    await generate(
      {
        mode_id: modeId || selectedModeId,
        message,
        session_id: sessionId,
        files,
        recording,
      },
      abortController.signal,
      {
        async onopen(response) {
          if (
            response.ok &&
            response.headers.get("content-type") === EventStreamContentType
          ) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500) {
            console.error("onopen", response);
            if (response.status === 413) {
              handleError(t(commonMessages.chatRequestEntityTooLarge));
            } else if (response.status === 429) {
              handleError(t(commonMessages.chatRequestTooManyTokens));
            } else handleError();
          }
        },
        onmessage(event) {
          const msg = JSON.parse(event.data) as ChatResponse;
          if (msg.type === "error") {
            console.error("onmessage", msg);
            if (msg.error_code === 429)
              return handleError(t(commonMessages.chatRequestTooManyTokens));
            else return handleError();
          }
          if (msg.type === "start") {
            setCurrentConversationId(msg.session_id);
            return;
          }
          if (msg.audio_url) {
            setCurrentTTSUrl(msg.audio_url);
            setIsPlaying(true);
          }
          const value = msg.value ?? "";
          const function_value = msg.function_value ?? "";
          const calling_function = msg.calling_function ?? false;

          if (msg.type === "transcript") {
            transcriptText = value;
          } else if (msg.type === "end") {
            runningText = value;
            runningFunctionText = function_value;
          } else {
            runningText += value;
            runningFunctionText += function_value;
          }

          const isTranscript = msg.type === "transcript";
          const role = isTranscript ? "human" : "ai";
          const content = isTranscript ? transcriptText : runningText;

          const chatMessage: ConversationMessage = {
            _id: msg.message_id,
            conversation_id: msg.session_id,
            content,
            function_content: runningFunctionText,
            created: new Date().toISOString(),
            rating: 0,
            role,
            sources: msg.sources,
            updated: new Date().toISOString(),
            calling_function: calling_function,
            standalone_question: msg.standalone_question,
            no_innio_context: msg.no_innio_context,
            mode_id: msg.mode_id,
            mode_name: msg.mode_name,
          };

          updateLastMessage(chatMessage);
        },
      }
    );

    setIsGenerating(false);
    queryClient.invalidateQueries("conversation");
  };

  const newChat = () => {
    setCurrentConversationId(undefined);
    setMessages([]);
  };

  const scrollToBottomOnNextTick = (
    arg?: boolean | ScrollIntoViewOptions | undefined
  ) => {
    setTimeout(() => {
      chatMessageStreamEnd.current?.scrollIntoView(arg);
    }, 0);
  };

  const disabledButton = () => {
    return isGenerating || (messages && messages.length === 0);
  };

  const onScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as EventTarget & HTMLDivElement;
    setIsScrolledBottom(checkScrolledBottom(target));
  };

  return (
    <div className="flex flex-col flex-1" role="main">
      <div className="z-10 md:absolute pb-0 p-4 sm:px-6">
        <div className="flex -mt-2 -ml-2 px-2 py-2 backdrop-blur-sm">
          <ModeMenu />
        </div>
      </div>
      <div
        className="full flex flex-col flex-1 overflow-y-auto"
        onScroll={onScroll}
        id="chat-container"
        ref={chatContainerRef}
        style={{
          paddingRight: isLg && isChatHistoryOpen ? drawerWidth : 0,
        }}
      >
        <div className="flex flex-col flex-1 pt-0 p-4 sm:px-6 max-w-5xl m-auto w-full">
          <AutoSizer>
            {({ height, width }: Size) => (
              <div style={{ height, width }}>
                {!messages || messages.length < 1 ? (
                  <Splash />
                ) : (
                  <div role="log">
                    {messages.map((answer, index) => (
                      <Fragment key={index}>
                        {answer.role === "human" ? (
                          <div className="my-4 flex justify-end" tabIndex={0}>
                            <Question answer={answer} />
                          </div>
                        ) : answer.role === "ai" ? (
                          <div className="my-4 md:max-w-[80%] md:min-w-[80%] flex">
                            <Answer
                              key={`${currentConversationId}|${index}`}
                              answer={answer}
                              showTyping={
                                isGenerating && index === messages.length - 1
                              }
                              onTypedTextChange={() => {
                                if (isScrolledBottom) {
                                  scrollToBottomOnNextTick();
                                }
                              }}
                            />
                          </div>
                        ) : answer.role === "error" ? (
                          <div className="my-4 md:max-w-[80%] md:min-w-[80%] flex">
                            <ErrorAnswer answer={answer} />
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                    <div ref={chatMessageStreamEnd} />
                    <div className="h-32"></div>
                  </div>
                )}

                <div
                  className="flex flex-col absolute bottom-0"
                  style={{
                    backgroundColor: theme.palette.background.default,
                    width: width,
                  }}
                >
                  {!isScrolledBottom && (
                    <div className="absolute right-0 -top-12">
                      <Tooltip title={t(intlMessages.scrollToBottom)}>
                        <IconButton
                          onClick={() => scrollToBottomOnNextTick()}
                          aria-label="scroll to bottom"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {isGenerating && (
                    <div
                      className="flex justify-end mb-2"
                      style={{
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        aria-label="Stop generating"
                        aria-hidden="true"
                        tabIndex={0}
                        onClick={stopGenerating}
                        onKeyDown={(e) =>
                          e.key === "Enter" || e.key === " "
                            ? stopGenerating()
                            : null
                        }
                        startIcon={<CheckBoxOutlineBlankIcon />}
                      >
                        Stop generating
                      </Button>
                    </div>
                  )}

                  <div className="flex items-center mb-4 z-[1]">
                    <div>
                      {messages.length > 0 && (
                        <div className="hidden 2lg:flex mr-2 2lg:-ml-14">
                          <IconButton
                            size="large"
                            onClick={newChat}
                            disabled={disabledButton()}
                            aria-label="start a new chat button"
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    <QuestionInput
                      clearOnSend
                      placeholder="Type a new question..."
                      disabled={isGenerating || hasError}
                      onSend={({
                        modeId,
                        message: message,
                        files,
                        sessionId: sessionId,
                        recording,
                      }) => {
                        makeGenerateRequest({
                          modeId,
                          message,
                          files,
                          sessionId,
                          recording,
                        });
                      }}
                      sessionId={currentConversationId}
                      loading={isGenerating}
                    />
                  </div>
                </div>
              </div>
            )}
          </AutoSizer>
        </div>
      </div>

      <ClickAwayListener
        onClickAway={() => {
          if (!isLg && isChatHistoryOpen) {
            setChatHistory(false);
          }
        }}
      >
        <Drawer
          open={isChatHistoryOpen}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 300,
              top: 0,
              height: "100%",
              boxSizing: "border-box",
              [theme.breakpoints.up("md")]: {
                top: 66,
                height: "calc(100% - 66px)",
              },
            },
          }}
          variant={!isLg ? undefined : "persistent"}
          onClose={() => setChatHistory(false)}
          anchor="right"
        >
          <ChatHistoryPanel />
        </Drawer>
      </ClickAwayListener>
    </div>
  );
}
