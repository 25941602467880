import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";

export function useModePreview(hash: string) {
  const client = useApiClient();
  return useQuery(["mode-previews", hash], () => {
    return client.fetch<{
      mode_creator: string;
      mode_id: string;
      mode_name: string;
      read_only: boolean;
      share_creator: string;
      share_hash: string;
    }>(`/api/modes/import/${hash}`, {});
  });
}
