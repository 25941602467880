import {
  List,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModeDeleteAccess } from "../../hooks/useModeShare";
import { Mode, SharedWith } from "../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIntl } from "react-intl";
import { commonMessages } from "../../commonMessages";

type Props = {
  mode?: Mode;
  users: SharedWith[];
};

export function ModeUserList({ mode, users }: Props) {
  const { formatMessage: t } = useIntl();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { mutateAsync: deleteAccess } = useModeDeleteAccess();
  return (
    <List dense className="max-h-96 overflow-y-auto">
      {users?.map(({ read_only, user: { username, email, full_name } }) => {
        return (
          <ListItem
            key={username}
            disableGutters
            secondaryAction={
              <>
                <Typography variant="caption" className="mr-2 md:mr-4">
                  {t(
                    read_only ? commonMessages.readonly : commonMessages.canEdit
                  )}
                </Typography>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() =>
                    deleteAccess({
                      mode_id: mode?._id || "",
                      shared_with_user_id: username,
                    })
                  }
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            }
            disablePadding
          >
            <ListItemButton>
              {!isSmall && (
                <ListItemAvatar>
                  <Avatar>
                    {(full_name || "")
                      .split(" ")
                      .map((n) => n[0].toUpperCase())
                      .join("")}
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                id={`shared-user-${username}`}
                primary={full_name}
                secondary={!isSmall && email}
                primaryTypographyProps={{
                  marginRight: "3rem",
                }}
                secondaryTypographyProps={{
                  marginRight: "3rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
