import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { forwardRef, useRef, useState } from "react";
import AttachFile from "@mui/icons-material/AttachFile";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  files: {
    id: "files.files",
    defaultMessage: "Specific Knowledge",
  },
  tooltip: {
    id: "files.tooltip",
    defaultMessage:
      "Documents are loaded to the Assistant and permanently considered to answer the user question.",
  },
});

export type FileWithPreview = File & {
  id?: string;
  preview?: string;
};

type Props = {
  files: FileWithPreview[];
  onDeleteFile: (file: File) => void;
};

export function FilesHeading() {
  const { formatMessage: t } = useIntl();
  return (
    <div className="flex items-center">
      <Typography variant="h6" id="input-slider">
        {t(messages.files)}
      </Typography>
      &nbsp;
      <Tooltip title={t(messages.tooltip)}>
        <HelpOutlineIcon fontSize="small" />
      </Tooltip>
    </div>
  );
}

export function FilesPreview({ files, onDeleteFile }: Props) {
  const theme = useTheme();
  const isImage = (file: File) => {
    return file.type.startsWith("image/");
  };
  return (
    <>
      {files.map((file) => (
        <div
          key={file.name}
          className="relative inline-flex border-2 border-dashed rounded-xl overflow-hidden ml-2 mb-2 h-28 min-w-[50px]"
        >
          <div
            className="absolute top-0 right-0 rounded-2xl"
            style={{
              backgroundColor: theme.palette.background.default,
            }}
          >
            <IconButton size="small" onClick={onDeleteFile.bind(null, file)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          {isImage(file) ? (
            <img
              className="block w-auto h-full"
              src={file.preview}
              // Revoke data uri after image is loaded
              onLoad={() => {
                if (file.preview) {
                  URL.revokeObjectURL(file.preview);
                }
              }}
            />
          ) : (
            <div
              className="p-2 flex items-center justify-center min-w-[100px]"
              style={{
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Typography variant="caption" className="font-bold">
                {file.name}
              </Typography>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export function useFileUploadProps() {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const ref = useRef<HTMLInputElement>(null);

  const clear = () => {
    setFiles([]);
    if (ref.current) {
      ref.current.value = "";
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (
    event
  ) => {
    setFiles([
      ...files,
      ...Array.from(event.target.files ?? []).map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
  };

  const onDeleteFile = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name));
    if (ref.current) {
      ref.current.value = "";
    }
  };

  return {
    previewProps: {
      files,
      setFiles,
      onDeleteFile,
    },
    buttonProps: {
      ref,
      onChange,
      clear,
    },
  };
}

export const FileUploadButton = forwardRef<
  HTMLInputElement,
  {
    id?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    accept?: string;
  }
>(function FileUploadButtonInner(
  {
    id = "attachment-upload",
    onChange,
    accept = "application/pdf,text/csv,text/xml,application/vnd.oasis.opendocument.text,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/json,image/jpeg,image/png,application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel",
  },
  ref
) {
  return (
    <Tooltip title="Upload Files">
      <label htmlFor={id}>
        <input
          ref={ref}
          hidden
          id={id}
          type="file"
          multiple
          onChange={onChange}
          accept={accept}
        />
        <IconButton aria-label="upload picture" component="span" size="small">
          <AttachFile fontSize="small" />
        </IconButton>
      </label>
    </Tooltip>
  );
});
