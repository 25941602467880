import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useMemoryDelete() {
    const client = useApiClient();
    const queryClient = useQueryClient();
    return useMutation(
      (id: string) => {
        return client.fetch(`/api/memory/${id}`, {
          method: "DELETE",
        });
      },
  
      {
        async onSettled() {
          await queryClient.invalidateQueries({
            queryKey: ["memory"],
          });
        },
      }
    );
  }