import { DialogActions, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogButton,
} from "@myplant-io/mui";
import { useSnackbar } from "@myplant-io/snackbar";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { useMatch, useNavigate } from "react-router-dom";
import { commonMessages } from "../../commonMessages";
import { useModeImport } from "../../hooks/useModeImport";
import { useModePreview } from "../../hooks/useModePreview";
import { RouteNames } from "../../RouteNames";

const FORM_ID = "mode-import-dialog";

const messages = defineMessages({
  title: {
    id: "modeImport.title",
    defaultMessage: "Import Mode",
  },
  confirmationText: {
    id: "modeImport.confirmationText",
    defaultMessage: `User {shareCreator} wants to share a mode named "{name}".

The mode was created by {creator}. {readonly, select, true {The mode is readonly and can only be changed by it's creator.} other {After the import you will be able to adjust it in the mode menu.}}\n
Would you like to import it now?
`,
  },
  importErrorText: {
    id: "modeImport.importErrorText",
    defaultMessage: "Failed to import mode.",
  },
});

export function ModeImport() {
  const { formatMessage: t } = useIntl();
  const navigate = useNavigate();
  const match = useMatch(RouteNames.MODE_IMPORT);
  const { showErrorMessage } = useSnackbar();
  const hash = match?.params.hash ?? "";

  const { data: modeData, isLoading } = useModePreview(hash);
  const { mutateAsync: importMode } = useModeImport();

  const { handleSubmit } = useForm();
  const handleClose = () => {
    navigate(RouteNames.CHAT);
  };

  const submit = async () => {
    try {
      await importMode({ hash });
      navigate(RouteNames.CHAT);
    } catch (error) {
      showErrorMessage({ message: t(messages.importErrorText) });
    }
  };

  return (
    <Dialog open>
      <DialogTitle onClose={handleClose}>{t(messages.title)}</DialogTitle>
      <DialogContent>
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(submit)}
          className="flex flex-col"
        >
          {!isLoading && (
            <Typography className="whitespace-pre-wrap">
              {t(messages.confirmationText, {
                shareCreator: modeData?.share_creator,
                name: modeData?.mode_name,
                creator: modeData?.mode_creator,
                readonly: modeData?.read_only,
              })}
            </Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <DialogButton form={FORM_ID} type="submit" variant="primary">
          {t(commonMessages.import)}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
