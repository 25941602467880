import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  Divider,
  TextField,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DocumentType, EnabledFunction } from "../../types";
import { Fragment } from "react";
import { useFunctions } from "../../hooks/useFunctions";
import { commonMessages } from "../../commonMessages";

const messages = defineMessages({
  function: {
    id: "function.source",
    defaultMessage: `{cnt, plural,
      =1 {Skill}
      other {Skills}
    }`,
  },
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SELECT_ALL = "SELECT_ALL";

type Props = {
  functions?: EnabledFunction[];
  onChange: (functions: EnabledFunction[]) => void;
  disabled?: boolean;
};

export function FunctionsAutocomplete({
  functions,
  onChange,
  disabled,
}: Props) {
  const { formatMessage: t } = useIntl();
  const { data: allFunctions } = useFunctions();

  const handleClick = (functions?: DocumentType[]) => {
    const _ids = functions?.map(({ id }) => id);
    const docs =
      allFunctions?.map((doc) => ({
        ...doc,
        selected: _ids?.includes(doc.id) ?? false,
      })) ?? [];
    onChange(docs);
  };

  const selectedFunctions = functions?.filter(({ selected }) => selected);
  const selectedCount = selectedFunctions?.length ?? 0;
  const isAllSelected = allFunctions?.length === selectedCount;

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={functions ?? []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedFunctions ?? []}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options, params) => {
          const filter = createFilterOptions<EnabledFunction>();
          const filtered = filter(options, params);
          return [
            {
              label: t(commonMessages.selectAll),
              id: SELECT_ALL,
              selected: isAllSelected,
              requiresInnioContext: false,
            },
            ...filtered,
          ];
        }}
        onChange={(_, newValue) => {
          if (newValue.find((option) => option.id === SELECT_ALL)) {
            return onChange(
              allFunctions?.length === selectedFunctions?.length
                ? []
                : allFunctions ?? []
            );
          }

          handleClick(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <Fragment key={option.id}>
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.id === SELECT_ALL ? isAllSelected : selected}
              />
              {option.label}
            </li>
            {option.id === SELECT_ALL && <Divider />}
          </Fragment>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option.label}
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={t(messages.function, { cnt: 2 })} />
        )}
      />
    </>
  );
}
