
import { AppToolbar } from "@myplant-io/app-components";
import { defineMessages, useIntl } from "react-intl";
import { useMemoryItems } from "../../hooks/useMemory";
import { useDataGridPremium } from "@myplant-io/mui";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
    DataGridPremium,
    GridActionsCellItem,
    GridColDef,
    GridOverlay,
    GridRowParams,
    useGridApiRef,
  } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { MemoryItem } from "../../types";
import { debounce } from "lodash";
import { useMemoryDelete } from "../../hooks/useMemoryDelete";
import { IconButton, Input, InputAdornment } from "@mui/material";

const messages = defineMessages({
    header: {
      id: "memory.header",
      defaultMessage: "Memory",
    },
    noRows: {
      id: "memory.noRows",
      defaultMessage: "No memories found",
    },
    number: {
        id: "memory.column.number",
        defaultMessage: "#",
    },
    id: {
      id: "memory.column.id",
      defaultMessage: "ID",
      flex: 1,
    },
    content: {
      id: "memory.column.content",
      defaultMessage: "Content",
    },
    created: {
        id: "memory.column.created",
        defaultMessage: "Created",
    },
    delete: {
        id: "memory.column.delete",
        defaultMessage: "Delete",
    },
    search: {
      id: "memory.search",
      defaultMessage: "Search",
    },
  });

  const useDebouncedPickerQuery = (timeout = 350) => {
    const [inputValue, setInputValue] = useState("");
    const [query, setQuery] = useState("");
  
    const debouncedSetQuery = useMemo(
      () => debounce(setQuery, timeout),
      [timeout]
    );
    const updateQuery = (query: string) => {
      debouncedSetQuery(query);
      setInputValue(query);
    };
  
    return {
      query,
      setQuery: updateQuery,
      inputValue,
    };
  };

export default function Memory() {

    const { formatMessage: t } = useIntl();
    const searchQuery = useDebouncedPickerQuery();
    const { data: allMemories, isLoading } = useMemoryItems();
    const { mutateAsync: deleteMemory } = useMemoryDelete();

    function CustomNoRowsOverlay() {
      return (
          <GridOverlay>
              <div style={{ padding: '20px', textAlign: 'center' }}>
              {t(messages.noRows)}
              </div>
          </GridOverlay>
      );
    }

    const columns: GridColDef<MemoryItem>[] = useMemo(
        () => [
          {
            field:"Nr",
            headerName: t(messages.number),
            width: 70,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
          },
          {
            field: "_id",
            headerName: t(messages.id),
            width: 250,
          },
          {
            field: "text",
            headerName: t(messages.content),
            width: 400,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                  {params.value}
                </div>
            ),
          },
          {
            field: "created",
            headerName: t(messages.created),
            width: 300,
            renderCell: (params) => {
                const date = new Date(params.value + 'Z')
                return (
                    <div>
                        {date.toLocaleString()}
                    </div>
                );
            },
          },      
          {
            field: "delete",
            headerName: t(messages.delete),
            width: 100,
            type: "actions",
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  key="link"
                  icon={<DeleteIcon />}
                  label={t(messages.delete)}
                  onClick={() => {
                    deleteMemory(params.id as string);
                  }}
                />,
              ],

          },  
        ],
        [t]
      );

      const apiRef = useGridApiRef();
      const { gridProps } = useDataGridPremium({
        apiRef,
        gridStateId: "MEMORY_GRID_STATE",
        columns,
        autoPageSize: true,
        initialState: {
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
          columns: {
            columnVisibilityModel: {
              _id: false,
            },
          },
        },
      });

      const memories = useMemo(() => {
        return (
          allMemories
          ?.filter(({ text }) =>
            text.toLowerCase().includes(searchQuery.query.toLowerCase())
          ) ?? []
        );
      }, [allMemories, searchQuery.query]);

return (
    <>
        <AppToolbar title={t(messages.header)} elevate/>
        <div className="flex flex-col flex-1 p-4 sm:px-6" role="main">
          <div className="flex flex-col h-full flex-grow max-w-5xl m-auto w-full ">
            <div className="flex justify-end my-4">
                <Input
                  data-testid="user-management-search"
                  id="search"
                  placeholder={t(messages.search)}
                  value={searchQuery.inputValue}
                  autoComplete="off"
                  onChange={(e) => searchQuery.setQuery(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={searchQuery.inputValue !== "" ? false : true}
                        aria-label="clear search"
                        onClick={() => searchQuery.setQuery("")}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="flex flex-col h-full flex-grow max-w-5xl m-auto w-full min-h-[400px]">
                  <AutoSizer>
                    {({ height, width }: Size) => (
                      <div style={{ height, width }}>
                        <DataGridPremium<MemoryItem>
                          {...gridProps}
                          loading={isLoading}
                          rows={memories ?? []}
                          disableRowSelectionOnClick
                          getRowId={(row) => row._id}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                          }}
                        />
                </div>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>

    </>
)
}