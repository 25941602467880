import { Alert, AlertTitle, IconButton } from "@mui/material";
import {
  BaseSuggestionData,
  MentionsTextField,
  MentionsTextFieldProps,
} from "@jackstenglein/mui-mentions";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import { LoadingAnimation } from "./LoadingAnimation";
import { useAppState } from "../../state/App";
import {
  FilesPreview,
  FileUploadButton,
  useFileUploadProps,
} from "../common/FilesSelector";
import { useModeName, useModes } from "../../hooks/useModes";
import { useReactMediaRecorder } from "react-media-recorder";
import CircularProgress from "@mui/material/CircularProgress";
import { defineMessages, useIntl } from "react-intl";
import { useState } from "react";

const mentionedRegex = /@[^\s]*$/;

const messages = defineMessages({
  recordingTitle: {
    id: "recording.title",
    defaultMessage: "Recording in progress",
  },
  recordingText: {
    id: "recording.text",
    defaultMessage: "Stop the recording to submit your question.",
  },
});

// Workaround to deal with MentionsTextField returning a ReactNode
function TextField<T extends BaseSuggestionData>(
  props: MentionsTextFieldProps<T>
) {
  return (
    <>
      {typeof MentionsTextField === "undefined"
        ? null
        : MentionsTextField(props)}
    </>
  );
}

type QuestionInputProps = {
  onSend: (args: {
    modeId?: string;
    message?: string;
    files?: File[];
    recording?: Blob;
    sessionId?: string;
  }) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  sessionId?: string;
  loading?: boolean;
};

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  sessionId,
  loading = false,
}: QuestionInputProps) => {
  const { formatMessage: t } = useIntl();
  const {
    state: { question, isPlaying },
    actions: { setQuestion, setCurrentTTSUrl, setIsPlaying },
  } = useAppState();

  const [strippedQuestion, setStrippedQuestion] = useState(question);
  const [mentionedModeId, setMentionedModeId] = useState<string | undefined>(
    undefined
  );

  const { previewProps, buttonProps } = useFileUploadProps();
  const { data: modes, isLoading: isModesLoading } = useModes();
  const getModeName = useModeName();

  const { status, error, startRecording, stopRecording } =
    useReactMediaRecorder({
      audio: true,
      mediaRecorderOptions: {
        mimeType: "audio/wav",
      },
      onStop: (_, recording: Blob) => {
        setCurrentTTSUrl(undefined);
        setIsPlaying(false);
        buttonProps.clear();
        onSend({ recording, sessionId, files: previewProps.files });
      },
    });

  const sendQuestionDisabled =
    loading || disabled || !question.trim() || isModesLoading;

  const sendQuestion = () => {
    if (sendQuestionDisabled) {
      return;
    }

    if (sessionId) {
      onSend({
        modeId: mentionedModeId,
        message: strippedQuestion || question,
        files: previewProps.files,
        sessionId,
      });
    } else {
      onSend({
        modeId: mentionedModeId,
        message: strippedQuestion || question,
        files: previewProps.files,
      });
    }

    if (clearOnSend) {
      setQuestion("");
      buttonProps.clear();
    }
  };

  // Unfornately, the MentionsTextField component does not provide a way to
  // distinguish in the `onKeyDown`-handler whether the user is pressing Enter
  // while accepting a mention suggestion or not. As a workaround, we check
  // the last word in the input and if it is a mention, do not send the question.
  const onEnterPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      ev.key === "Enter" &&
      !ev.shiftKey &&
      ev.currentTarget.value.trim() !== "" &&
      !mentionedRegex.test(ev.currentTarget.value)
    ) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const hasMicPermission = !(
    status === "permission_denied" || error === "permission_denied"
  );
  const isAcquiringMedia = status === "acquiring_media";
  const isRecording = status === "recording";

  return (
    <div className="w-full flex flex-col">
      <div className="relative">
        {isRecording && (
          <Alert icon={<GraphicEqIcon fontSize="inherit" />} severity="info">
            <AlertTitle>{t(messages.recordingTitle)}</AlertTitle>
            {t(messages.recordingText)}
          </Alert>
        )}
        <div className="w-full flex flex-wrap absolute bottom-0">
          <div className="w-full flex justify-end">
            <FilesPreview {...previewProps} />
          </div>
        </div>
      </div>
      <div className="w-full flex align-middle">
        <TextField
          dataSources={[
            {
              data: mentionedModeId
                ? []
                : modes?.map((mode) => ({
                    id: mode._id,
                    display: getModeName(mode._id),
                  })) || [],
              displayTransform: (id) => `@${getModeName(id)}`,
              appendSpaceOnAdd: true,
            },
          ]}
          autoFocus
          fullWidth
          placeholder={placeholder}
          multiline
          minRows={1}
          maxRows={10}
          value={question}
          onChange={(v, o, m) => {
            let stripped = o;
            // remove mentions from the question
            m.forEach(({ dataSourceIndex, display }) => {
              if (dataSourceIndex === 0) {
                stripped = stripped.replace(display, "").trim();
              }
            });
            setStrippedQuestion(stripped);
            setMentionedModeId(m[0]?.id);
            setQuestion(v);
          }}
          InputProps={{
            onKeyDown: onEnterPress,
            startAdornment: (
              <div className="flex">
                <FileUploadButton
                  id="question-input-attachment"
                  {...buttonProps}
                />
              </div>
            ),
            endAdornment: (
              <>
                {question.length === 0 ? (
                  hasMicPermission ? (
                    <IconButton
                      disabled={loading}
                      tabIndex={0}
                      onClick={(e) => {
                        e.preventDefault();
                        isPlaying
                          ? setIsPlaying(false)
                          : isRecording
                          ? stopRecording()
                          : startRecording();
                      }}
                    >
                      {isAcquiringMedia ? (
                        <CircularProgress size={24} />
                      ) : isRecording || isPlaying ? (
                        <StopCircleIcon color="primary" />
                      ) : (
                        <MicIcon color="primary" />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton disabled={!hasMicPermission}>
                      <MicOff color="disabled" />
                    </IconButton>
                  )
                ) : null}
                {question.length > 0 && !isRecording && (
                  <IconButton
                    disabled={sendQuestionDisabled}
                    tabIndex={0}
                    onClick={sendQuestion}
                    onKeyDown={(e) =>
                      e.key === "Enter" || e.key === " " ? sendQuestion() : null
                    }
                  >
                    {loading ? (
                      <LoadingAnimation />
                    ) : (
                      <SendIcon color="primary" />
                    )}
                  </IconButton>
                )}
              </>
            ),
          }}
        />
      </div>
    </div>
  );
};

QuestionInput.displayName = "QuestionInput";
