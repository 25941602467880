import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppState } from "../../state/App";
import { defineMessages, useIntl } from "react-intl";
import AutoAwesome from "@mui/icons-material/AutoAwesome";
import { defaultMode, useModeName, useModes } from "../../hooks/useModes";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import {
  ModeUpdateDialog,
  messages as modeUpdateMessages,
} from "./ModeUpdateDialog";
import { useModeCreate } from "../../hooks/useModeCreate";
import { useModeUpdate } from "../../hooks/useModeUpdate";
import { useModeDelete } from "../../hooks/useModeDelete";
import { Mode } from "../../types";
import { ModeShareDialog } from "./ModeShareDialog";
import { getTemperatureLabel } from "./TemperatureSlider";
import { useModeAttachmentsAdd } from "../../hooks/useModeAttachmentsAdd";
import { useModeAttachmentsDelete } from "../../hooks/useModeAttachmentsDelete";
import { commonMessages } from "../../commonMessages";
import { useSnackbar } from "@myplant-io/snackbar";
import { ApiError } from "@myplant-io/utils/api";
import { useModeRagSourcesAdd } from "../../hooks/useModeRagSourceAdd";
import { useModeRagSourcesDelete } from "../../hooks/useModeRagSourceDelete";

export const messages = defineMessages({
  addMode: {
    id: "modeMenu.addMode",
    defaultMessage: "Add mode",
  },
  documentTypes: {
    id: "modeMenu.documentTypes",
    defaultMessage: `{cnt, plural,
      =0 {No Document Types}
      =1 {{cnt} Document Type}
      other {{cnt} Document Types}
    }`,
  },
});

export function ModeMenu() {
  const { formatMessage: t } = useIntl();
  const { showErrorMessage } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    state: { isGenerating, modeId: mode },
    actions: { setModeId: setMode },
  } = useAppState();
  const [showDialog, setShowDialog] = useState(false);
  const [editMode, setEditMode] = useState<Mode | undefined>();
  const [sharedMode, setSharedMode] = useState<Mode | undefined>();
  const menuOpen = Boolean(anchorEl);

  const handleError = (error: ApiError<unknown>) => {
    if (error.statusCode === 413) {
      showErrorMessage({
        message: t(commonMessages.chatRequestEntityTooLarge),
      });
    } else if (error.statusCode === 429) {
      showErrorMessage({
        message: t(commonMessages.chatRequestTooManyTokens),
      });
    }
  };

  const { data: modes } = useModes();
  const getModeName = useModeName();
  const { mutateAsync: createMode } = useModeCreate({
    onError: handleError,
  });
  const { mutateAsync: updateMode } = useModeUpdate({
    onError: handleError,
  });
  const { mutateAsync: deleteMode } = useModeDelete();
  const { mutateAsync: addAttachments } = useModeAttachmentsAdd({
    onError: handleError,
  });
  const { mutateAsync: deleteAttachments } = useModeAttachmentsDelete();
  const { mutateAsync: addRagSources } = useModeRagSourcesAdd();
  const { mutateAsync: deleteRagSources } = useModeRagSourcesDelete();

  const selectedMode = modes?.find((m) => m._id === mode);

  // select a system mode by default
  useEffect(() => {
    if (modes && !mode) {
      const m = modes.find((m) => m.name === defaultMode);
      if (m) {
        setMode(m._id);
      }
    }
  }, [mode, modes, setMode]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (mode: string) => {
    handleMenuClose();
    setMode(mode);
  };

  const handleModeChange = (m: Mode | undefined) => {
    handleMenuClose();
    setEditMode(m);
    setShowDialog(true);
  };

  const handleModeClose = () => {
    setShowDialog(false);
  };

  const handleShareDialogClose = () => {
    setSharedMode(undefined);
  };

  const summarizeMode = (mode: Mode) => {
    const conversationStyleLabel = getTemperatureLabel(mode.temperature);
    const conversationStyle = conversationStyleLabel
      ? t(conversationStyleLabel)
      : "";

    const documentTypes = mode.no_innio_context
      ? t(modeUpdateMessages.noInnioContext)
      : t(messages.documentTypes, { cnt: mode.document_types?.length ?? 0 });

    return `${conversationStyle}, ${documentTypes}`;
  };

  return (
    <>
      <div className="flex">
        <Button
          id="menu-button"
          disabled={isGenerating}
          aria-controls={menuOpen ? "menu-button" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          variant="contained"
          sx={{ textTransform: "none" }}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {getModeName(mode)}
        </Button>
        <StyledMenu
          MenuListProps={{
            "aria-labelledby": "munu-button",
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          {modes?.map((mode) => (
            <MenuItem
              key={mode._id}
              selected={mode._id === selectedMode?._id}
              disableRipple
              onClick={handleMenuItemClick.bind(null, mode._id)}
            >
              <ListItemIcon>
                <AutoAwesome />
              </ListItemIcon>
              <ListItemText
                primary={getModeName(mode._id)}
                secondary={summarizeMode(mode)}
              ></ListItemText>
              {mode?.is_system_mode == false && (
                <div className="ml-4">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModeChange(mode);
                    }}
                    size="large"
                    edge={mode.read_only ? "end" : "start"}
                    sx={{
                      "& .MuiSvgIcon-root": { marginRight: "0 !important" },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {!mode.read_only && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSharedMode(mode);
                      }}
                      size="large"
                      edge="end"
                      sx={{
                        "& .MuiSvgIcon-root": { marginRight: "0 !important" },
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                </div>
              )}
            </MenuItem>
          ))}
        </StyledMenu>
        <div className="ml-2">
          <Tooltip title={t(messages.addMode)}>
            <IconButton
              aria-label={t(messages.addMode)}
              onClick={handleModeChange.bind(null, undefined)}
              disabled={isGenerating}
            >
              <AddIcon className="mr-0!" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <ModeUpdateDialog
        open={showDialog}
        onClose={handleModeClose}
        onSubmit={async (mode) => {
          if (mode._id) {
            // compare the attachments and update the mode
            const attachmentIds = mode.attachments.map(({ id }) => id);
            const existingAttachments =
              modes?.find(({ _id }) => _id === mode._id)?.attachments || [];
            const deletedAttachments = existingAttachments.filter(
              ({ _id }) => !attachmentIds.includes(_id)
            );
            const addionalAttachments = mode.attachments.filter(
              ({ id }) => typeof id === "undefined"
            );

            if (deleteAttachments.length > 0) {
              await deleteAttachments({
                modeId: mode._id,
                attachmentIds: deletedAttachments.map(({ _id }) => _id),
              });
            }
            if (addionalAttachments.length > 0) {
              await addAttachments({
                modeId: mode._id,
                attachments: addionalAttachments,
              });
            }

            //compare the rag sources and update the mode
            const ragSourceIds = mode.ragSources.map(({ _id }) => _id);
            const existingRagSources =
              modes
                ?.find(({ _id }) => _id === mode._id)
                ?.rag_sources.map(({ _id }) => _id) || [];
            const deletedRagSources = existingRagSources.filter(
              (id) => !ragSourceIds.includes(id)
            );
            const additionalRagSources = mode.ragSources.filter(
              ({ _id }) => _id === "-1"
            );

            if (deletedRagSources.length > 0) {
              await deleteRagSources({
                modeId: mode._id,
                ragSourceIds: deletedRagSources.map((id) => id),
              });
            }

            if (additionalRagSources.length > 0) {
              await addRagSources({
                modeId: mode._id,
                ragSources: additionalRagSources,
              });
            }

            await updateMode({
              _id: mode._id,
              name: mode.name,
              temperature: mode.temperature,
              system_prompt_addition: mode.systemPromptAddition,
              document_types: mode.documentTypes,
              enabled_functions: mode.enabledFunctions,
              no_innio_context: mode.noInnioContext,
              disable_animation: mode.disableAnswerAnimation,
            });
          } else {
            const { _id } = await createMode({
              name: mode.name,
              temperature: mode.temperature,
              systemPromptAddition: mode.systemPromptAddition,
              documentTypes: mode.documentTypes,
              enabledFunctions: mode.enabledFunctions,
              noInnioContext: mode.noInnioContext,
              disableAnimation: mode.disableAnswerAnimation,
              attachments: mode.attachments,
              ragSources: mode.ragSources,
            });
            setMode(_id);
          }
          handleModeClose();
        }}
        onDelete={async (id) => {
          await deleteMode(id);
          if (selectedMode?._id === id) {
            setMode(undefined);
          }
          handleModeClose();
        }}
        mode={editMode}
      />
      <ModeShareDialog
        mode={sharedMode}
        open={!!sharedMode}
        onClose={handleShareDialogClose}
      />
    </>
  );
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
