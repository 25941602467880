import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { DocumentType } from "../types";

export const useDocumentTypes = () => {
  const client = useApiClient();
  return useQuery<DocumentType[]>(["documentTypes"], () =>
    client
      .fetch("/api/knowledge-base/document-type")
      .then((res: Record<string, string>) =>
        Object.entries(res)
          .map(([key, value]) => ({ id: key, label: value, selected: true }))
          .sort((a, b) => a.label.localeCompare(b.label))
      )
  );
};
