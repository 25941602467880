import { Typography, useTheme } from "@mui/material";
import { AILogoNeg, AILogoPos } from "@myplant-io/logos";

export function Splash() {
  const theme = useTheme();
  return (
    <div className="h-full  flex flex-col justify-center items-center text-center">
      <div
        style={{
          height: "66px",
        }}
      >
        {theme.palette.mode === "dark" ? <AILogoNeg /> : <AILogoPos />}
      </div>
      <Typography variant="h4" className="mt-8">
        Start chatting
      </Typography>
      <Typography className="font-light mt-2">
      myAI Assistant has access to INNIO documents and knowledge bases.
      </Typography>
    </div>
  );
}
