import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useModeImport() {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ hash }: { hash: string }) => {
      return client.fetch(`/api/modes/import/${hash}`, {
        method: "POST",
      });
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
    }
  );
}
