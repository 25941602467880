import { TextField, Typography } from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@myplant-io/mui";
import { defineMessages, useIntl } from "react-intl";
import { CopyToClipboardButton } from "./CopyToClipboardButton";
import { useModeAccess } from "../../hooks/useModeAccess";
import { useModeShareHash } from "../../hooks/useModeShare";
import { Mode } from "../../types";
import { ModeUserPicker } from "./ModeUserPicker";
import { ModeUserList } from "./ModeUserList";

const messages = defineMessages({
  title: {
    id: "modeShare.title",
    defaultMessage: "Share Mode",
  },
  shareByLink: {
    id: "modeShare.shareByLink",
    defaultMessage: "Grant access by sharing a link:",
  },
  shareLink: {
    id: "modeShare.shareLink",
    defaultMessage: "Share Link",
  },
  shareLinkReadonly: {
    id: "modeShare.shareLinkReadonly",
    defaultMessage: "Share Link (Readonly)",
  },
  shareWithUsers: {
    id: "modeShare.shareWithUsers",
    defaultMessage: "Grant access to the following users:",
  },
  users: {
    id: "modeShare.users",
    defaultMessage: "Users that have access",
  },
  usersWithEdit: {
    id: "modeShare.usersWithEdit",
    defaultMessage: "Users that have edit access",
  },
});

type Props = {
  mode?: Mode;
  open: boolean;
  onClose: () => void;
};

export function ModeShareDialog({ mode, open, onClose }: Props) {
  const { formatMessage: t } = useIntl();
  const handleClose = () => {
    onClose();
  };
  const { data: hash } = useModeShareHash({ modeId: mode?._id });
  const { data: readonlyHash } = useModeShareHash({
    modeId: mode?._id,
    readonly: true,
  });
  const { data: access } = useModeAccess(mode?._id);

  const sharedWith = (access?.shared_with || []).sort((a, b) =>
    a.read_only > b.read_only
      ? 1
      : a.read_only === b.read_only
      ? (a.user.full_name ?? "").localeCompare(b.user.full_name ?? "")
      : -1
  );

  const generateLink = (h: string) => {
    return `${window.location.origin}/mode-import/${h}`;
  };

  const link = hash && generateLink(hash.share_hash);
  const readonlyLink = generateLink(readonlyHash?.share_hash || "");

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle onClose={handleClose}>{t(messages.title)}</DialogTitle>
      <DialogContent>
        <div>
          <Typography>{t(messages.shareByLink)}</Typography>
        </div>
        {link && (
          <div className="mt-4 flex items-center">
            <TextField
              size="small"
              label={t(messages.shareLink)}
              disabled
              value={link}
              fullWidth
              className="mr-4"
            />
            <CopyToClipboardButton text={link} size="small" />
          </div>
        )}
        <div className="mt-4 flex items-center">
          <TextField
            size="small"
            label={t(messages.shareLinkReadonly)}
            disabled
            value={readonlyLink}
            fullWidth
            className="mr-4"
          />
          <CopyToClipboardButton text={readonlyLink} size="small" />
        </div>
        <div>
          <Typography className="mt-8">{t(messages.shareWithUsers)}</Typography>
        </div>
        <div className="mt-4">
          <ModeUserPicker mode={mode} />
        </div>
        {sharedWith.length > 0 && (
          <>
            <div className="mt-8">
              <Typography>{t(messages.users)}</Typography>
            </div>
            <ModeUserList mode={mode} users={sharedWith} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
