import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { ConversationMessage } from "../types";

export const useConversation = (
  id?: string,
  onSuccess?: (data: ConversationMessage[]) => void
) => {
  const client = useApiClient();
  return useQuery<ConversationMessage[]>(
    ["conversation", id],
    () =>
      client
        .fetch(`/api/history/conversation/${id}/message`)
        .then((res) => (Array.isArray(res) ? res : [])),
    {
      enabled: Boolean(id),
      onSuccess,
    }
  );
};
