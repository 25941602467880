import "katex/dist/katex.min.css"; // rehype-katex does not import the CSS
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import tsx from "react-syntax-highlighter/dist/esm/languages/prism/tsx";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import supersub from "remark-supersub";
import classes from "./MarkdownRenderer.module.css";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Divider, useTheme } from "@mui/material";
import {
  oneDark,
  oneLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboardButton } from "../common/CopyToClipboardButton";
import { useEffect } from "react";

SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("python", python);

type Props = {
  markdown: string;
  inline?: boolean;
};

const bracketReplace = /\\\[(.+)\\\]/g;

function GalleryImage({
  src,
  srcSet,
  alt,
  thumbnail,
  width,
  height,
}: React.ImgHTMLAttributes<HTMLImageElement> & { thumbnail?: string }) {
  // preload image
  useEffect(() => {
    const t = setTimeout(() => {
      const img = new Image();
      img.src = src ?? "";
    }, 100);
    return () => clearTimeout(t);
  }, [src]);

  return (
    <Item
      original={src}
      originalSrcset={srcSet}
      thumbnail={thumbnail ?? src}
      width={width}
      height={height ?? width}
    >
      {({ ref, open }) => (
        <img
          ref={ref}
          alt={alt}
          className="cursor-pointer"
          aria-hidden="true"
          onClick={(e) => {
            e.preventDefault();
            open(e);
          }}
          src={thumbnail ?? src}
        />
      )}
    </Item>
  );
}

export function MarkdownRenderer({ markdown, inline }: Props) {
  const theme = useTheme();
  const syntaxTheme = theme.palette.mode === "dark" ? oneDark : oneLight;

  const preprocessMarkdown = (markdownText: string) => {
    // Replace \[ with $$ and \] with $$ to ensure compatibility
    const processedText = markdownText.replace(bracketReplace, "$$$$$1$$$$");
    return processedText;
  };

  return (
    <Gallery withDownloadButton>
      <ReactMarkdown
        linkTarget="_blank"
        remarkPlugins={[
          remarkGfm,
          supersub,
          [
            remarkMath,
            {
              singleDollarTextMath: false,
            },
          ],
        ]}
        // @ts-expect-error workaround for rehype-katex types
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={{
          hr() {
            return <Divider className="my-2"/>;
          },
          img({ src, srcSet, alt, width, height, node }) {
            const thumbnail = node.properties?.["dataThumbnail"];
            if (typeof thumbnail === "undefined") {
              return <img src={src} alt={alt} className="w-full" />;
            }
            return (
              <GalleryImage
                src={src}
                srcSet={srcSet}
                alt={alt}
                thumbnail={thumbnail?.toString()}
                width={width}
                height={height}
              />
            );
          },
          code({ className, inline, children, ...props }) {
            const hasLang = /language-(\w+)/.exec(className || "");
            const content = String(children);

            return hasLang ? (
              <div className="relative">
                {!inline && (
                  <div className="absolute right-4 top-4">
                    <CopyToClipboardButton text={content} />
                  </div>
                )}
                <SyntaxHighlighter
                  style={syntaxTheme}
                  language={hasLang[1]}
                  PreTag="div"
                  className="codeStyle"
                  showLineNumbers={true}
                  wrapLines
                  useInlineStyles={true}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
        className={inline ? classes["markdown-inline"] : undefined}
      >
        {preprocessMarkdown(markdown)}
      </ReactMarkdown>
    </Gallery>
  );
}
