import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { RagSource } from "../types";

export function useModeRagSourcesAdd(
  options?: UseMutationOptions<
    unknown,
    ApiError,
    { modeId: string; ragSources: RagSource[] }
  >
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    ({ modeId, ragSources }) => {
      return Promise.all(
        ragSources.map((ragSource) =>
          client.fetch(`/api/modes/${modeId}/rag-sources`, {
            method: "POST",
            body: JSON.stringify(ragSource),
          })
        )
      );
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
