import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import { commonMessages } from "../../commonMessages";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
};

const messages = defineMessages({
  title: {
    id: "rating.title",
    defaultMessage: "Feedback",
  },
  hint: {
    id: "rating.hint",
    defaultMessage: "Please provide additional feedback (optional).",
  },
  comment: {
    id: "rating.comment",
    defaultMessage: "Comment",
  },
});

export function RatingDialog({ open, onClose, onSubmit }: Props) {
  const { formatMessage: t } = useIntl();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const comment = formJson.comment;
            onSubmit(comment.toString());
          },
        }}
      >
        <DialogTitle>{t(messages.title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t(messages.hint)}</DialogContentText>
          <TextField
            multiline
            rows={4}
            autoFocus
            margin="dense"
            id="comment"
            name="comment"
            label={t(messages.comment)}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t(commonMessages.cancel)}</Button>
          <Button type="submit" variant="contained">
            {t(commonMessages.submit)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
