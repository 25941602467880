import { useApiClient } from "@myplant-io/utils/api";
import { useMutation, useQueryClient } from "react-query";

export function useRateMessage() {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      conversationId,
      messageId,
      rating,
      comment,
    }: {
      conversationId: string;
      messageId: string;
      rating: number;
      comment?: string;
    }) =>
      client.fetch(
        `/api/history/conversation/${conversationId}/message/${messageId}`,
        {
          method: "PUT",
          body: JSON.stringify({ rating, comment }),
        }
      ),
    {
      async onSettled(_, __, id) {
        queryClient.removeQueries(["conversation", id]);
        await queryClient.invalidateQueries({
          queryKey: ["conversation"],
        });
      },
    }
  );
}
