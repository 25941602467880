import { useApiClient } from "@myplant-io/utils/api";
import { useQuery } from "react-query";
import { useAppState } from "../state/App";
import { Mode } from "../types";

export function useSelectedMode() {
  const {
    state: { modeId: mode },
  } = useAppState();
  const client = useApiClient();
  return useQuery<Mode>(["modes", mode], () =>
    client.fetch(`/api/modes/${mode}`)
  );
}
