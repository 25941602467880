import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { FormattedMessage } from "react-intl";

type Props = IconButtonProps;

export function RegenerateButton({ ...rest }: Props) {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="regenerateButton.tooltip"
          defaultMessage="Regenerate"
        />
      }
    >
      <IconButton {...rest}>
        <ReplayIcon fontSize={rest.size} />
      </IconButton>
    </Tooltip>
  );
}
